import { isEmpty } from "./utils"

const MODE_IN_SEQUENCE = 1
const MODE_AT_ONCE = 2

export default class TaskExecutor {

  constructor() {
    this.tasksMap = new Map()
    this.callback = null
    this.runMode = MODE_AT_ONCE
    this.isRunning = false
  }

  clear() {
    this.tasksMap.clear()
    this.isRunning = false
  }

  addTask(id,task) {
    if(this.isRunning) return
    this.tasksMap.set(id,task)
  }

  setTasksCompleteCallback(callback) {
    this.callback = callback
  }

  runAll() {
    if(this.isRunning) {
      this.tryFinish()
      return
    }

    this.isRunning = true
    this.runMode = MODE_AT_ONCE 

    this.tasksMap.forEach((v,k) => {
      const task = this.tasksMap.get(k)
      if(task) task()
    })
  }

  runInSequence() {
    if(this.isRunning) {
      this.tryFinish()
      return
    }
    
    this.isRunning = true
    this.runMode = MODE_IN_SEQUENCE

    const keys = this.tasksMap.keys()
    if(this.isFinished()) {
      this.tryFinish()
    } 
    else {
      const key = keys.next()
      if(key.done) {
        this.tryFinish()
      }
      else {
        const task = this.tasksMap.get(key.value)
        if(task) task()
        else this.finish(key.value)
      }
    }
  }

  finish(taskId) {
    this.tasksMap.delete(taskId)
    if(this.runMode === MODE_IN_SEQUENCE) {
      this.isRunning = false
      this.runInSequence()
    }
    else {
      this.tryFinish()
    }
  }

  tryFinish() {
    if(this.isFinished())
      if(this.callback) this.callback()
  }

  isFinished() {
    return this.tasksMap.size === 0
  }
  
}