import appContext from "@/AppContext"
import { TYPE_MAP, TYPE_NEARBY, TYPE_PLACE, TYPE_VENUE } from "@/models/Constants"
import "@/styles/common.scss"
import { geocode, isEmpty } from "@/utils.js"
import DataView from "@/views/DataView.jsx"
import Option from "@/views/Option.jsx"
import React from "react"
import "./styles/Filter.scss"

/** Returns type {postcode, nearby} or changing postcode */

export default class LocationFilter extends React.PureComponent {

  constructor(props) {
    super(props)
    this.timeout = null
    const { place } = props.filter
    this.state = {
      place: place.name ? place.name : "",
      message: null,
      isLocating: false
    }
  }

  onLocationChanged = (location) => {
    this.props.onChange({location:{...this.props.filter, ...location}}, true) 
  }

  getPlace = (name) => {
    geocode(name, (loc,err) => {
      if(this.state.place !== name) return
      if(err) {
        this.setState({isLocating: false, message: "Location not found"}, 
          () => this.onLocationChanged({place: {...this.props.filter.place, lat:0, lng:0, name:name} }))
      }
      else {
        this.setState({isLocating: false, message: "Location found"},
          () => this.onLocationChanged({ place: {...this.props.filter.place, ...loc, name: name} }))
      }
    })
  }

  onTypeChanged = (type) => {
    this.onLocationChanged({type: type})
  }
  
  onPostcodeChanged = (e) => {
    const place = e.target.value
    const isLocating = place.length > 0
    const message = isLocating ? "Locating..." : null
    this.setState({place: place, isLocating: isLocating, message: message}, 
      () => {
        clearTimeout(this.time)
        if(place.length === 0) {
          this.onLocationChanged({ place: {...this.props.filter.place, name:null, lat: 0, lng: 0} })
        }
        else {
          this.time = setTimeout(()=>this.getPlace(place), 500)
        }
    })
  }

  renderPostcode = () => {

    const postcodeStyle = {
      width: "80%",
      height: "2em",
      display: "block",
      padding: "4px",
      margin: "0.5em auto 0 auto",
      border: "1px solid #ccc",
    }

    const messageStyle = {
      width:"80%",
      textAlign: "end",
      fontSize: "small",
      fontColor: "black",
      margin: "0.2em auto 0.2em auto",
    }

    const focus = this.props.filter.type === TYPE_PLACE

    return (
      <div style={{display:"flex", flexDirection:"column", backgroundColor: "white"}}>
        <input 
          style={postcodeStyle} 
          placeholder="Enter postcode"
          value={this.state.place}
          autoFocus={focus}
          onChange={this.onPostcodeChanged}/>
          <span style={messageStyle}>{this.state.message}</span>
      </div>
    )
  }

  renderOptions = (options) => {
    return (
      <div> 
        {
          options.map(opts => {
            if(opts.key == TYPE_PLACE && opts.props.checked) 
              return (
                <div key={opts.key}>{opts}{this.renderPostcode()}</div>
              )
            else return opts
        })}
      </div>
    )
  }

  hasLocation = (coords) => {
    return coords.lat !== 0 && coords.lng !== 0
  }

  renderMenu = (options) => {
    const { nearby, place, venue, type } = this.props.filter
    let text

    if(type === TYPE_NEARBY) {
      text = "Nearby - " + (this.hasLocation(nearby) ? "Found" : "Not found")
    }
    else if(type === TYPE_PLACE) {
      if(isEmpty(place.name)) text = "Not found"
      else text = `${place.name} - ${this.hasLocation(place) ? "Found" : "Not found"}`
    }
    else if(type === TYPE_VENUE) {
      text = venue.name
    }
    else if(type === TYPE_MAP) {
      text = "Map"
    }
    else {
      text = "Not set"
    }

    return (
      <div className="fp-dropdown filter-box">
        <div className="filter">
          <DataView style={this.props.style} title="Location" subtitle={text} hideIcon />
        </div>
        <div className="fp-dropdown-box">
          <div className="filter-options fp-shadow">
            {options.map(opts => {
              if(opts.key == TYPE_PLACE && type == TYPE_PLACE) 
                return (
                  <div key={opts.key}>{opts}{this.renderPostcode()}</div>
                )
              else return opts
            })}
          </div>
        </div>
      </div>
    )
  }

  onVenueSelected = (venue) => {
    this.onLocationChanged({type: TYPE_VENUE, venue:venue})
  }

  renderDivider = () => {
    return <div style={{width:"100%",height:"1px",backgroundColor:"#F5F5F5"}}></div>
  }

  render() {
    const buttons = []
    const type = this.props.filter.type
    const venue = this.props.filter.venue

    const locationOptions = []

    if(this.props.isNearbyEnabled) locationOptions.push([TYPE_NEARBY, "Nearby"])
    if(this.props.isSearchEnabled) locationOptions.push([TYPE_PLACE, "Postcode"])
    
    buttons.push(
      ...locationOptions
        .map(item => (
          <Option
            type="radio"
            id={item[0]} 
            key={item[0]}
            name="location" 
            text={item[1]} 
            onChange={()=>this.onTypeChanged(item[0])} 
            checked={type === item[0]}/>
        ))
    )

    this.props.venues.forEach((v,index) => {
      buttons.push(
        <Option 
          type="radio"
          id={index}
          key={index}
          name="location" 
          text={v.name}
          onChange={(e) => this.onVenueSelected(v)}
          checked = {type === TYPE_VENUE && v === venue}
        />
      )
    });

    const elements = appContext.isMobile() ? 
      this.renderOptions(buttons) :
      this.renderMenu(buttons)

    return elements
  }

}