import analytics from "@/Analytics";
import api from "@/api/Api.js";
import appContext from "@/AppContext.js";
import Map from "@/components/main/map/Map.jsx";
import SignUp from "@/components/SignUp.jsx";
import { ReactComponent as BackArrow } from "@/images/arrow.svg";
import { ReactComponent as LastActiveIcon } from "@/images/last-active.svg";
import { ReactComponent as PinIcon } from "@/images/pin.svg";
import { DEFAULT_AVATAR, MODE_PLAYER } from "@/models/Constants.js";
import Sports from "@/models/Sports.js";
import User from "@/models/User";
import "@/styles/common.scss";
import { getLastActiveText, getVar, isEmpty } from "@/utils.js";
import Button from "@/views/Button";
import HorizontalBar from "@/views/HorizontalBar";
import Modal from "@/views/Modal.jsx";
import Toast from "@/views/Toast.jsx";
import { AttributionControl } from "mapbox-gl";
import React from "react";
import Skeleton from "react-loading-skeleton";
import ReactMapboxGl from "react-mapbox-gl";
import "./styles/Player.scss";

let UserMapbox = ReactMapboxGl({
  accessToken: appContext.getMapboxToken(),
  interactive: false,
  logoPosition: "bottom-left",
  attributionControl: false,
});

export default class Player extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      message: null,
      id: props.id,
      user: props.user,
    };
    this.imgRef = React.createRef();
    this.sports = props.user ? Sports.getByIds(props.user.getSportIds()) : [];
  }

  componentDidMount() {
    if (this.props.user) return;
    api.getUser(this.state.id, (res, err) => {
      if (err) {
        this.setState({ message: "Error fetching user" });
        return;
      }
      const user = new User(res.user);
      this.setState({ message: null, user });
    });
    analytics.logPage("page_user");
  }

  joinClicked = () => {
    appContext.showModal("signup", this.renderSignup);
  };

  renderSignup = () => {
    return (
      <Modal
        style={{
          width: "90%",
          maxWidth: "680px",
        }}
        name="signup"
        showClose={!appContext.isMobile()}
      >
        <SignUp
          mode={MODE_PLAYER}
          data={this.formatData()}
          shareUrl={this.state.user.getBranchUrl()}
        />
      </Modal>
    );
  };

  formatData = () => {
    const user = this.state.user;
    return {
      type: "user",
      id: this.state.id,
      name: user.getName(),
      avatar_url: user.getAvatarUrl(),
    };
  };

  onMapLoaded = (map) => {
    map.addControl(new AttributionControl(), "top-left");
  };

  onVenueClicked = (venue) => {
    const mapUrl =
      "https://www.google.com/maps/dir/?api=1&destination=" +
      encodeURI(venue.address);
    window.open(mapUrl);
  };

  renderMobileView = () => {
    const user = this.state.user;
    const location = user.getLocation();
    const fitness = user.getFitness();
    const reliability = user.getReliability();
    const sports = this.renderSports(this.sports);
    const lastActiveText = getLastActiveText(user.getLastActive());
    const pinColor = getVar("--fp-player-mobile-icon-color");
    const buttonStyle = {
      container: {
        padding: "0",
        justifyContent: "flex-start",
        display: "flex",
        flexDirection: "row",
        columnGap: "1em",
        alignItems: "center",
      },
      text: {},
    };

    return (
      <div className="mobile-view">
        <div className="mobile-stats">
          <div>
            <span>Fitness</span>
            <HorizontalBar
              width="100%"
              height="10px"
              color={getVar("--fp-player-fitness-bar-color")}
              progress={fitness}
            />
          </div>
          <div>
            <span>Reliability</span>
            <HorizontalBar
              width="100%"
              height="10px"
              color={getVar("--fp-player-reliability-bar-color")}
              progress={reliability}
            />
          </div>
        </div>
        <div className="mobile-info">
          <Button
            text={location}
            style={buttonStyle}
            icon={<PinIcon stroke={pinColor} />}
          />
          <Button
            text={lastActiveText}
            style={buttonStyle}
            icon={<LastActiveIcon stroke={pinColor} width={16} />}
          />
        </div>
      </div>
    );
  };

  renderStats = (fitness, reliability, sports) => (
    <div className="stats">
      <div className="stat">
        <span>Fitness</span>
        <HorizontalBar
          progress={fitness}
          width="140px"
          height="10px"
          color={getVar("--fp-player-fitness-bar-color")}
        />
      </div>
      <div className="stat">
        <span>Reliability</span>
        <HorizontalBar
          progress={reliability}
          width="140px"
          height="10px"
          color={getVar("--fp-player-reliability-bar-color")}
        />
      </div>
      <div>{sports}</div>
    </div>
  );

  renderSports = (sports) => {
    return sports.map((sport) => {
      const style = { backgroundColor: sport.getBackgroundColor() };
      return (
        <div className="sportContainer">
          <img
            className="sportItem"
            style={style}
            src={sport.getIconUrl()}
            alt=""
            key={sport.getId()}
          />
        </div>
      );
    });
  };

  renderBackButton = () => (
    <div className="fp-back-button" onClick={this.props.onClose}>
      <BackArrow />
    </div>
  );

  renderMobileSkeletonView = () => (
    <div className="mobile-view">
      <div className="mobile-stats">
        <div>
          <Skeleton width={80} />
          <Skeleton width={160} />
        </div>
        <div>
          <Skeleton width={80} />
          <Skeleton width={160} />
        </div>
      </div>
      <div className="mobile-info">
        <Skeleton width={80} />
        <br />
        <Skeleton width={80} />
      </div>
    </div>
  );

  renderSkeleton = () => {
    const isMobile = appContext.isMobile();
    return (
      <div id="fp-user-page">
        <div className="user">
          <div className="user-container">
            {this.state.message ? <Toast message={this.state.message} /> : null}
            <div className="user-map">
              {appContext.isMobile() ? this.renderBackButton() : null}
              <div className="info">
                <Skeleton count={1} width={180} />
                <Skeleton count={1} width={180} />
              </div>
            </div>
            <div className="header">
              <img className="avatar" alt="" />
              <Skeleton count={1} width={80} />
            </div>
            {isMobile && this.renderMobileSkeletonView()}
            <div className="user-info">
              <div className="description">
                <Skeleton count={1} width={160} />
                <br />
                <Skeleton count={1} width={80} />
              </div>
            </div>
          </div>
          <div className="user-bottom">
            {isMobile ? null : (
              <div className="last-active">
                <Skeleton width={80} />
              </div>
            )}
            <Skeleton width={160} height={40} />
          </div>
        </div>
      </div>
    );
  };

  render() {
    const user = this.state.user;
    if (!user) return this.renderSkeleton();
    const loc = user.getCoordinates();
    let avatarUrl = user.getAvatarUrl();

    avatarUrl =
      isEmpty(avatarUrl) || avatarUrl.endsWith("default_player.png")
        ? DEFAULT_AVATAR
        : avatarUrl;

    const lastActive = getLastActiveText(user.getLastActive());
    const gender = user.getGender();
    const fitness = user.getFitness();
    const reliability = user.getReliability();

    const styles = {
      body: {
        backgroundColor: getVar("--fp-event-mobile-info-background-color"),
      },
      title: { color: getVar("--fp-event-mobile-info-title-color") },
      subtitle: { color: getVar("--fp-event-mobile-info-subtitle-color") },
    };

    let profileImage = (
      <img
        alt=""
        className="avatar"
        ref={this.imgRef}
        src={avatarUrl}
        onError={(e) => {
          e.target.src = DEFAULT_AVATAR;
        }}
      />
    );

    const sportImgs = this.renderSports(this.sports);
    const isMobile = appContext.isMobile();

    return (
      <div id="fp-user-page">
        <div className="user-container">
          {this.state.message ? <Toast message={this.state.message} /> : null}
          <div className="user-map">
            <Map
              instance={UserMapbox}
              zoom={14}
              showZoom={false}
              center={loc}
              mode={MODE_PLAYER}
              pinOnly
              data={[this.state.user]}
              onMapLoaded={this.onMapLoaded}
              noClick
            />
            {appContext.isMobile() ? this.renderBackButton() : null}
            <div className="info">
              <span>{user.getLocation()}</span>
              {this.renderStats(fitness, reliability, sportImgs)}
            </div>
          </div>
          <div className="header">
            {profileImage}
            <span>{user.getName()}</span>
          </div>
          {isMobile && this.renderMobileView()}
          <div className="user-info">
            <div className="description">
              {user.getAbout("No description set")}
            </div>
          </div>
        </div>

        <div className="user-bottom">
          {isMobile ? null : (
            <div className="last-active">
              <LastActiveIcon />
              <span>{lastActive}</span>
            </div>
          )}
          <button
            className="fp-shadow fp-unselectable"
            onClick={this.joinClicked}
          >
            Message
          </button>
        </div>
      </div>
    );
  }
}
