import { Component } from 'react'

class Disableable extends Component {
  render() {
    const { disabled, children } = this.props;
    return disabled ? null : children;
  }
}
/* 
Disableable.propTypes = {
  disabled: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired
};
 */
export default Disableable;