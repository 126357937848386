import React from "react"
import { Popup } from "react-mapbox-gl"
import "./styles/OrgPopup.scss"
import "@/styles/common.scss"
import {DEFAULT_EVENT_AVATAR} from "@/models/Constants";

export default function IminEventPopup(props) {
  const event = props.event
  const loc = event.getCoordinates()
  const avatarSrc = event.imageSrc

  return (
    <Popup
      coordinates={[loc.lng, loc.lat]}
      anchor="top"
      onClick={props.onClick}>
        <div className="user-popup-container fp-unselectable fp-shadow">
          <img
            className="avatar"
            src={avatarSrc}
            alt=""
            onError={(e) => {e.target.src=DEFAULT_EVENT_AVATAR}}
          />
          <div className="info">
            <span className="name">{event.name}</span>
            <span className="sports" style={{marginLeft: "1em"}}>{event.location.name}</span>
          </div>
        </div>
    </Popup>
  )
}
