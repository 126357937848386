import {TYPE_NEARBY} from "./Constants"

const Filter = {
  gender: "any",
  name: "",
  location: {
    type: TYPE_NEARBY,
    nearby: {lat: 0, lng: 0, zoom: 16},
    map: {lat: 0, lng: 0, zoom: 16},
    place: {lat: 0, lng: 0, name: "", zoom: 16},
    venue: {lat: 0, lng: 0, name: "", zoom: 18},
    searchDistanceInMiles: 10,
  },
  days: [],
  times: [],
  time_ranges: {
    from: {
      hour: null,
      minute: null
    },
    to: {
      hour: null,
      minute: null
    }
  },
  sports: [],
  team_type: [],
  team_level: []
}

export default Filter
