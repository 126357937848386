import React from "react";
import Toast from "@/views/Toast";
import { DEFAULT_FACILITY_AVATAR, MODE_IMIN_FACILITY } from "@/models/Constants";
import Map from "@/components/main/map/Map.jsx";
import ReactMapboxGl from "react-mapbox-gl";
import appContext from "@/AppContext";
import analytics from "@/Analytics";
import { AttributionControl } from "mapbox-gl";
import { ReactComponent as BackArrow } from "@/images/arrow.svg";
import "./styles/Facility.scss";
import DataView from "@/views/DataView";
import { getVar } from "@/utils";
import Disableable from "@/views/Disableable";

const EventMapbox = ReactMapboxGl({
  accessToken: appContext.getMapboxToken(),
  interactive: false,
  logoPosition: "bottom-left",
  attributionControl: false,
});

const styles = {
  body: {
    backgroundColor: getVar("--fp-facility-mobile-info-background-color"),
  },
  title: { color: getVar("--fp-facility-mobile-info-title-color") },
  subtitle: { color: getVar("--fp-facility-mobile-info-subtitle-color") },
  icon: { fill: getVar("--fp-facility-mobile-info-icon-color") },
};

export default class Facility extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      message: null,
      id: props.id,
      facility: props.facility,
    };
    this.imgRef = React.createRef();
  }

  componentDidMount() {
    analytics.logPage("page_facility");
  }

  onMapLoaded = (map) => {
    map.addControl(new AttributionControl(), "top-left");
  };

  onBook = (slot) => {
    window.open(slot.checkout);
  };

  onVenueClicked = (venue) => {
    const geo = venue.getCoordinates();
    const mapUrl =
            "https://www.google.com/maps/search/?api=1&query=" +
            encodeURI(`${geo.lat}, ${geo.lng}`);
    window.open(mapUrl);
  };

  renderBackButton = () => (
    <div className="fp-back-button" onClick={this.props.onClose}>
      <BackArrow />
    </div>
  );

  renderAmenities = (amenity, index) => {
    return (
      <span key={index}>
        {amenity.name}
      </span>
    );
  };

  renderFacilitySlotItem = (slot, index) => {
    console.log(slot);
    return (
      <div className="slot-item" key={index}>
        <div className="slot-item__time">
          <span className="time">{slot.getFormattedTime()}</span>
          <span className="duration">For {slot.getDuration()}</span>
        </div>
        <div className="slot-item__price">
          <span className="price">
            {slot.getFormattedCost()}
          </span>
          <Disableable disabled={slot.checkout === false}>
            <button
              className="fp-shadow fp-unselectable"
              onClick={() => this.onBook(slot)}
            >
              Book
            </button>
          </Disableable>

          <Disableable disabled={slot.slot.remainingUses !== 0}>
            <button
              className="fp-shadow fp-unselectable sold-out"
              disabled={true}
            >
              SOLD OUT
            </button>
          </Disableable>
        </div>
      </div>
    );
  };

  renderMobileView() {

  }

  render() {
    const isMobile = appContext.isMobile();
    const facility = this.state.facility;

    const loc = facility.getCoordinates();
    const address = facility.getAddress();

    const amenities = facility.getAmenities();
    const amenityList = amenities.map((item, index) => this.renderAmenities(item, index));

    const slots = facility.getSlots();
    const slotList = slots.map((item, index) => this.renderFacilitySlotItem(item, index));

    const profileImage = (
      <img
        alt=""
        className="avatar"
        ref={this.imgRef}
        src={facility.imageSrc}
        onError={(e) => {
          e.target.src = DEFAULT_FACILITY_AVATAR;
        }}
      />
    );

    return (
      <div id="fp-facility-page">
        <div className="facility-container">
          {this.state.message ? <Toast message={this.state.message} /> : null}

          {/* MAP */}
          <div className="facility-map">
            <Map
              instance={EventMapbox}
              data={[facility]}
              zoom={15}
              showZoom={false}
              center={loc}
              mode={MODE_IMIN_FACILITY}
              onMapLoaded={this.onMapLoaded}
              noClick
            />

            {appContext.isMobile() ? this.renderBackButton() : null}

            <div className="info">
              <div className="address">
                <span className="name">{facility.location.name}</span>
                <span>{address}</span>
              </div>

              <div className="amenities">
                {amenityList}
              </div>
            </div>
          </div>
          {/* End of MAP */}

          <div className="header">
            {profileImage}
            <span>{facility.name}</span>
          </div>

          <div className="facility-info">
            <div className="place-and-amenities">
              <DataView
                style={styles}
                title={facility.location.name}
                subtitle={address}
                onClick={() => this.onVenueClicked(facility)}
              />
              <div className="amenities">
                {amenityList}
              </div>
            </div>

            <div className="description">
              {facility.description}
            </div>

            <div className="slots">
              <span className="date">{facility.getFormattedDate()}</span>

              <div className="slots-container">
                {slotList}
              </div>
            </div>
          </div>

        </div>
      </div>
    );
  }

}
