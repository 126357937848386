import appContext from "@/AppContext.js"
import { MODE_CLUB, MODE_EVENT, MODE_PLAYER } from "@/models/Constants.js"
import "@/styles/common.scss"
import { getModeText } from "@/utils"
import Option from "@/views/Option.jsx"
import React from "react"
import "./styles/Filter.scss"
import "./styles/SearchModeFilter.scss"


export default class SearchModeFilter extends React.PureComponent {

  onModeChanged = (newMode) => {
    appContext.setMode(newMode)
    if(this.props.onChange) this.props.onChange(newMode)
  }
  
  renderMobileOptions = (options) => {
    return (
      <div id="search-mode-container"> 
        {options} 
      </div>
    )
  }

  renderMenu = (options) => {
    var text = getModeText(this.props.mode, this.props.modes)
    return (
      <div className="fp-dropdown filter-box">
        <div className="filter">
          <button className="mode fp-shadow">{text}</button>
        </div>
        { 
          options.length === 1 ? null :
          <div className="fp-dropdown-box">
            <div className="filter-options fp-shadow">
              {options}
            </div>
          </div>
        }
      </div>
    )
  }

  render() {
    const mode = this.props.mode
    const options = this.props.modes.map(m => (
        <Option
          type="radio"
          id={m.id} 
          key={m.id}
          name="mode" 
          text={m.name} 
          onChange={()=>this.onModeChanged(m.id)} 
          checked={mode === m.id}/>
      ))

    const elements = appContext.isMobile() ? 
      this.renderMobileOptions(options) :
      this.renderMenu(options)

    return elements
  }

}