import { log } from "@/utils"

class Api {

  constructor(baseUrl) {
      this.baseUrl = baseUrl
      log("Api Url", baseUrl)
  }

  setBaseUrl(baseUrl) {
      if(!baseUrl || baseUrl.length === 0) return
      if(baseUrl.charAt(baseUrl.length-1) !== '/')
          baseUrl = baseUrl + '/'
          
      this.baseUrl = baseUrl
  }

  getUser(sessionId, callback) {
    this.get(this.baseUrl + "sessions/" + sessionId, callback)
  }

  getOrg(orgId, callback) {
    this.get(this.baseUrl + "organisations/" + orgId, callback)
  }

  getPlayers(filter, callback) {
    this.post(this.baseUrl + "people/search", filter, callback)
  }

  getEvents(filter, callback) {
    this.post(this.baseUrl + "events/search", filter, callback)
  }

  getOrgs(filter, callback) {
    this.post(this.baseUrl + "organisations/search", filter, callback)
  }

  getEvent(eventId, callback) {
      this.get(this.baseUrl + "events/" + eventId, callback)
  }

  getAllSports(callback) {
      this.get(this.baseUrl + "sports", callback)
  }

  getSports(sportIds, callback) {
      this.post(this.baseUrl + "sports/find", {"id":sportIds}, callback)
  }

  getTags(callback) {
    this.get(this.baseUrl + "tags", callback)
  }

  get(url, callback) {
    return this.executeRequest(this.buildRequest("GET",url,undefined), callback)
  }
  
  post(url, body, callback) {
      return this.executeRequest(this.buildRequest("POST", url, body), callback)
  }

  buildRequest(method, url, body) {
    const controller = new AbortController()
    let req = new Request(url, {
      method: method,
      signal: controller.signal,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    })
    setTimeout(() => controller.abort(), 10000)
    return req;
  } 
  
  executeRequest(req, callback) {
      try {
        fetch(req)
        .then(response => {
            if(!callback) return
            if(response.status < 200 || response.status >= 300) {
                callback(null, response)
            }
            else {
                response.json().then(json => callback(json, null))
            }
        }).catch(err => callback && callback(null, {status: 0}))
      } catch(err) {
          if(callback) callback(null, {status: 0})
      }
  }

}

const _Api = new Api(process.env.REACT_APP_API_URL)
export default _Api