import appContext from "@/AppContext"
import Tags from "@/models/Tags"
import "@/styles/common.scss"
import { log } from "@/utils"
import DataView from "@/views/DataView.jsx"
import Option from "@/views/Option.jsx"
import React, { PureComponent } from "react"
import "./styles/Filter.scss"


export default class TagFilter extends React.PureComponent {

  onChange = (e) => {
    const el = e.currentTarget
    const name = e.target.name
    let tags = [...this.props.filter]
    if(el.checked) tags.push(name)
    else tags = tags.filter(t => t !== name)
    this.props.onChange({[this.props.type]: tags})
  }

  renderOptions = (options) => {
    return ( <div> {options} </div> )
  }

  renderMenu = (options) => {
    const count = options.reduce((a,b)=>a+(b.props.checked?1:0), 0)

    return (
      <div className="fp-dropdown filter-box">
        <div className="filter">
          <DataView 
            style={this.props.style} 
            title={this.props.title}
            subtitle={count > 0 ? count + " selected" : "Any"} hideIcon />
        </div>
        <div className="fp-dropdown-box">
          <div className="filter-options shadow">
            {options}
          </div>
        </div>
      </div>
    )
  }

  render() {
    const tags = Tags.get(this.props.type)
    const selectedTags = this.props.filter

    const buttons = tags.map(tag => (
      <Option
        type="checkbox"
        id={tag.slug}
        key={tag.slug}
        name={tag.slug}
        text={tag.name} 
        onChange={this.onChange} 
        checked={selectedTags.includes(tag.slug)}
      />
    ))


    const options = appContext.isMobile() ? 
      this.renderOptions(buttons) : 
      this.renderMenu(buttons)

    return options
  }

}