import { isEmpty } from "@/utils.js"
import Sport from "./Sport"
import Play from "@/models/Play.js"
import User from "@/models/User.js"

export default class Event {

  constructor(event) {
    this.event = event
    this.sport = new Sport(event.sport)

    const plays = event.plays
    let play = null
    let id = 0

    for(let i=0; i<plays.length; i++) {
      if(id < plays[i].id) {
        play = plays[i]
        id = play.id
      }
    }

    if(play) this.event.plays = [play]
    else this.event.plays = []
  }

  getObject() {
    return this.event
  }

  getId() {
    return this.event.id
  }

  getShareUrl() {
    return this.event.share_url
  }

  getName() {
    return this.event.name
  }

  getSport() {
    return this.sport
  }

  getOrganiser() {
    const group = this.event.group
    let admin = group ? group.group_members.find(m => m.is_admin) : null
    if(admin) admin = new User(admin.session)
    if(!admin && this.event.creator) admin = new User(this.event.creator)
    return admin
  }

  getPlay() {
    if(isEmpty(this.event.plays)) return null
    return new Play(this.event.plays[0])
  }
  
  getGroup() {
    return this.event.group
  }

  getGender() {
    return this.event.gender
  }

  getCoordinates() {
    return this.getPlay().getLocation()
  }

  getStartTime() {
    const play = this.event.plays[0]
    return new Date(play.time_start)
  }

  getEndTime() {
    const play = this.event.plays[0]
    return new Date(play.time_end)
  }

  getLastActive() {
    const lastActive = this.event.last_active
    if(lastActive === undefined || lastActive === null) return null
    return new Date(lastActive)
  }

  getCost() {
    const cost = parseFloat(this.event.price)
    return cost > 0 ? cost + 0.45 : 0
  }

  getFormattedCost() {
    const cost = this.getCost()
    return cost > 0 ? `£${cost.toFixed(2)}` : 'FREE'
  }

  getDescription(defaultText) {
    return isEmpty(this.event.description) ? defaultText : this.event.description
  }
}