import {makeIminFilter, serialize} from '@/utils';
import { MODE_IMIN_EVENT, MODE_IMIN_FACILITY } from "@/models/Constants";

class ApiImin {
  constructor(baseUrl) {
    this.baseUrl = baseUrl;
  }

  buildRequest(method, url, body) {
    const controller = new AbortController();
    let req = new Request(url, {
      method: method,
      signal: controller.signal,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body)
    });
    setTimeout(() => controller.abort(), 10000);
    return req;
  }

  executeRequest(req, callback) {
    try {
      fetch(req)
        .then(response => {
          if (!callback) return
          if (response.status < 200 || response.status >= 300) {
            callback(null, response)
          } else {
            response.json().then(json => callback(json, null))
          }
        }).catch(err => callback && callback(null, {status: 0}))
    } catch (err) {
      if (callback) callback(null, {status: 0})
    }
  }

  get(url, callback) {
    return this.executeRequest(this.buildRequest("GET", url, undefined), callback)
  }

  post(url, body, callback) {
    return this.executeRequest(this.buildRequest("POST", url, body), callback)
  }

  getFacilities(params, callback) {
    params = makeIminFilter(params, MODE_IMIN_FACILITY);

    this.post(this.baseUrl + '/facility', params, callback);
  }

  getEvents(params, callback) {
    params = makeIminFilter(params, MODE_IMIN_EVENT);

    this.post(this.baseUrl + '/event', params, callback);
  }
}

export default new ApiImin(process.env.REACT_APP_IMIN_API_URL);
