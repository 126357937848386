import { formatCurrency } from "@/utils";
import { formatDate } from "@/time";
import { DEFAULT_EVENT_AVATAR } from '@/models/Constants';
import {amenitiesNames} from "@/iminUtils";
import IminEventSlot from "@/models/IminEventSlot";
import dayjs from "dayjs";
import isoWeek from "dayjs/plugin/isoWeek"
dayjs.extend(isoWeek)

export default class IminEvent {
    constructor(event) {
        this.imin_event = event;
        const {name, image, description} = event;

        this.name = name;
        this.description = description ?? '';
        this.imageSrc =image
          ? image[0].url
          : DEFAULT_EVENT_AVATAR;


        this.location =  event['imin:locationSummary'][0];
        const {address, amenityFeature} = this.location;

        this.address = address['imin:fullAddress']

        this.amenities = amenityFeature;
        this.aggregatePrice = event['imin:aggregateOffer']
            ? (event['imin:aggregateOffer'].publicAdult ?? event['imin:aggregateOffer'].publicJunior)
            : null;
    }

    getId() {
        return this.imin_event.id || null;
    }

    getAddress() {
        return this.address
            ? this.address
            .split(',')
            .slice(1)
            .filter(item => item !== '')
            .map(item => item.trim())
            .join(', ')
            : '';
    }

    getActivities() {
        return this.imin_event.activity || []
    }

    getAmenities() {
        const amenities = this.amenities?.filter(item => item.value) || []
        return amenitiesNames(amenities);
    }

    getSlots() {
        let sub_events = [];

        const today_weekday = dayjs().isoWeekday();
        const weekdays = [
          'schema:Monday',
          'schema:Tuesday',
          'schema:Wednesday',
          'schema:Thursday',
          'schema:Friday',
          'schema:Saturday',
          'schema:Sunday',
        ];

        if (this.imin_event?.subEvent[0]?.subEvent) {
           sub_events = this.imin_event?.subEvent[0]?.subEvent?.map(item => new IminEventSlot(item))
                .sort((a,b) => {
                    return (a.slot.startDate > b.slot.startDate) ? 1 : -1
                })
              || [];
        } else {
            this.imin_event?.subEvent[0].eventSchedule.forEach(item => {
                const partial_schedules = item.byDay.map(byday_item => {
                    const target_week = weekdays.indexOf(byday_item);
                    let target_day = dayjs().isoWeekday(target_week + 1);

                    if (today_weekday > target_week) {
                        target_day = dayjs().add(1, 'weeks').isoWeekday(target_week + 1);
                    }

                    return new IminEventSlot({
                        ...item,
                        startDate: target_day.format('YYYY-MM-DDT') + item.startTime
                    })
                })

                sub_events = [...sub_events, ...partial_schedules]
            });

            sub_events = sub_events?.sort((a,b) => {
                  return (a.slot.startDate > b.slot.startDate) ? 1 : -1
              }) || [];
        }

        return sub_events;
    }

    getDate() {
        return this.getSlots()[0]?.slot?.startDate || false
    }

    getFormattedDate() {
        const date = this.getDate();
        return date
            ? formatDate(new Date(date))
            : null;
    }

    getCoordinates() {
        return {
            lat: this.location.geo.latitude,
            lng: this.location.geo.longitude
        }
    }

    getFormattedCost() {
        return this.aggregatePrice
            ? this.aggregatePrice.price !== 0
                ? formatCurrency(this.aggregatePrice.price, this.aggregatePrice.priceCurrency)
                : 'FREE'
            : null;
    }
}
