import analytics from "@/Analytics";
import appContext from "@/AppContext.js";
import { IS_STAGING_ENV, MODE_EVENT, MODE_PLAYER } from "@/models/Constants";
import { RES_ANIM_APP, RES_ANIM_SENDING_2 } from "@/models/Res.js";
import store from "@/Store.js";
import "@/styles/common.scss";
import { loadBranch, log, isEmpty } from "@/utils.js";
import { diffInHours } from "@/time.js";
import lottie from "lottie-web";
import React from "react";
import "./SignUp.scss";

const SMS_SENT_COUNT = "sms_sent_count";
const SMS_SENT_TIMESTAMP = "sms_sent_timestamp";
const SMS_SEND_LIMIT = 3;

export default class SignUp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      branchLoaded: false,
      errorMessage: null,
      isSendingSms: false,
      smsSent: false,
      isEditingCountryCode: false,
      countryCode: "+44",
      mobileNumber: "",
      errors: {},
    };

    const countryCode = store.get("countryCode");
    if (!isEmpty(countryCode)) this.state.countryCode = countryCode;

    this.mobileNumberRef = React.createRef();
    this.countryCodeRef = React.createRef();
    this.firstNameRef = React.createRef();
    this.surnameRef = React.createRef();
    this.dobRef = React.createRef();

    this.pageName = `page_signup_${props.mode}`;
  }

  componentDidMount() {
    this.anim = lottie.loadAnimation({
      container: this.animBox,
      renderer: "svg",
      loop: false,
      autoplay: true,
      path: RES_ANIM_APP,
    });

    if (window.branch) this.setState({ branchLoaded: true });
    else this.initBranch();

    analytics.logModal(this.pageName);
  }

  initBranch = () => {
    loadBranch((err, data) => {
      if (err) this.branchError(err);
      else this.setState({ branchLoaded: true, errorMessage: null });
    });
  };

  branchError(err) {
    this.setState({
      branchLoaded: false,
      errorMessage: "Initialisation failed",
    });
    log(err);
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevState.smsSent && this.state.smsSent) {
      this.successAnim = lottie.loadAnimation({
        container: this.animBox,
        renderer: "svg",
        loop: true,
        autoplay: true,
        path: RES_ANIM_SENDING_2,
      });
    }
  }

  onUpdateCountryCode = (e) => {
    const codeInput = e.currentTarget;
    this.setCountryCode(codeInput.value);
  };

  setCountryCode = (code) => {
    code = code.trim();
    if (isEmpty(code)) {
      code = this.state.countryCode;
    }
    if (code[0] !== "+") code = "+" + code;
    store.set("countryCode", code);

    this.setState({ isEditingCountryCode: false, countryCode: code }, () => {
      this.mobileNumberRef.current.focus();
    });
  };

  onCountryCodeKeyDown = (e) => {
    if (e.key === "Enter") {
      this.setCountryCode(e.currentTarget.value);
    }
  };

  onInputChange = (e) => {
    const input = e.currentTarget;
    if (isNaN(parseInt(input.value.slice(-1)))) {
      input.value = input.value.substring(0, input.value.length - 1);
    }
    this.saveInput(e);
  };

  renderMobileInput = () => {
    return (
      <div className="mobile-field">
        {this.state.isEditingCountryCode ? (
          <input
            autoFocus
            className="country-code-input"
            data-key="countryCode"
            onChange={this.onInputChange}
            onKeyDown={this.onCountryCodeKeyDown}
            onBlur={this.onUpdateCountryCode}
            placeholder={this.state.countryCode.replace("+", "")}
          ></input>
        ) : (
          <span
            ref={this.countryCodeRef}
            className="country-code"
            defaultValue={store.get("countryCode")}
            onClick={() => this.setState({ isEditingCountryCode: true })}
          >
            {this.state.countryCode}
          </span>
        )}
        <input
          ref={this.mobileNumberRef}
          className="mobile-number-input"
          defaultValue={store.get("mobileNumber")}
          data-key="mobileNumber"
          onChange={this.onInputChange}
          type="tel"
          placeholder="e.g. 0712345678"
        />
      </div>
    );
  };

  gotAppClicked = () => {
    window.open(this.props.shareUrl);
  };

  sendClicked = () => {
    if (!this.state.branchLoaded) return;

    if (!IS_STAGING_ENV && store.getInt(SMS_SENT_COUNT) === SMS_SEND_LIMIT) {
      const time = store.getInt(SMS_SENT_TIMESTAMP, 0);
      if (diffInHours(new Date(time), new Date()) >= 24) {
        store.setInt(SMS_SENT_COUNT, 0);
      } else {
        alert("Sms limit reached");
        return;
      }
    }

    const countryCode = this.state.countryCode;
    let mobileNumber = this.mobileNumberRef.current.value.trim();
    const firstName = this.firstNameRef.current.value.trim();
    const surname = this.surnameRef.current.value.trim();
    const dob = this.dobRef.current.value;

    const errors = {};

    if (isEmpty(firstName)) errors.firstName = "First name missing";
    if (isEmpty(surname)) errors.surname = "Surname missing";

    if (
      isEmpty(mobileNumber) ||
      mobileNumber.length < 7 ||
      mobileNumber.length > 12
    ) {
      errors.mobileNumber = "Invalid mobile number";
    } else if (mobileNumber.startsWith("0")) {
      mobileNumber = mobileNumber.substring(1);
    }

    if (isEmpty(dob)) {
      errors.dob = "Date of birth missing";
    } else if (!this.isOver18(dob)) {
      errors.dob = "You must be 18 or over";
    }

    const hasErrors = Object.keys(errors).length > 0;

    this.setState({ errors, isSendingSms: !hasErrors }, () => {
      if (Object.keys(this.state.errors).length === 0) {
        this.sendSms({
          first_name: firstName,
          surname,
          country_code: countryCode,
          mobile_number: mobileNumber,
          dob,
        });
      }
    });
  };

  saveInput = (el) => {
    const input = el.target;
    store.set(input.dataset.key, input.value);
  };

  getDesktopUrl = (id) => {
    let type;

    if (this.props.mode === MODE_EVENT) type = "event_id";
    else if (this.props.mode === MODE_PLAYER) type = "player_id";
    else type = "club_id";

    return `https://www.findaplayer.com/local-search.php?${type}=${id}/`;
  };

  sendSms = (input) => {
    const number = input.country_code + input.mobile_number;
    const data = { ...this.props.data, user_details: input };
    const id = data.id;

    let url, uri, tag;

    const desktopUrl = this.getDesktopUrl();
    const androidUrl = "market://details?id=com.findaplayer.letsplay";
    const iosUrl = "itms-apps://itunes.apple.com/app/id662978301";
    const mode = this.props.mode;
    const scheme = appContext.isStaging() ? "testfap" : "fap";

    if (mode === MODE_EVENT) {
      uri = `${scheme}://event/${id}`;
      tag = "EventSearch";
    } else if (mode === MODE_PLAYER) {
      if (data.org_id) {
        uri = `${scheme}://organisation/${data.org_id}`;
        tag = "ContactOrgAdmin";
      } else if (data.event_id) {
        uri = `${scheme}://event/${data.event_id}`;
        tag = "ContactEventAdmin";
      } else {
        uri = `${scheme}://user/${id}`;
        tag = "ContactPlayer";
      }
    } else {
      uri = `${scheme}://organisation/${id}`;
      tag = "OrgSearch";
    }

    var linkData = {
      tags: [tag],
      campaign: "Find a Player Widget",
      channel: "findaplayer",
      feature: "TextMeTheApp",
      data: {
        url: desktopUrl,
        uri: uri,
        signup: data,
        $ios_url: iosUrl,
        $android_url: androidUrl,
        $desktop_url: desktopUrl,
        $android_deepview: false,
        $ios_deepview: false,
      },
    };
    var options = {
      make_new_link: false,
      open_app: true,
    };

    log(data);

    window.branch.sendSMS(number, linkData, options, (err, result) => {
      if (err) {
        this.setState({
          isSendingSms: false,
          errorMessage:
            "Something went wrong! Please check your mobile number.",
        });
        console.error(err);
      } else {
        this.setState({
          smsSent: true,
          isSendingSms: false,
          errorMessage: null,
        });
        store.setInt(SMS_SENT_COUNT, store.getInt(SMS_SENT_COUNT, 0) + 1);
        store.setInt(SMS_SENT_TIMESTAMP, new Date().getTime());
      }
    });
  };

  isOver18 = (dobStr) => {
    const now = new Date();
    const dob = new Date(Date.parse(dobStr));

    const yearDiff = now.getFullYear() - dob.getFullYear();
    const monthDiff = now.getMonth() - dob.getMonth();
    const dateDiff = now.getDate() - dob.getDate();

    if (yearDiff < 18 || yearDiff > 99) return false;
    if (yearDiff > 18) return true;

    if (monthDiff > 0) return true;
    if (monthDiff < 0) return false;

    if (dateDiff >= 0) return true;

    return false;
  };

  renderSuccess = () => {
    analytics.logModal(this.pageName + "_success");
    const heading = (
      <span className="heading">
        We've just sent a magic link to your phone.
      </span>
    );

    return (
      <div id="fp-success-container">
        {heading}
        <div ref={(ref) => (this.animBox = ref)} />
      </div>
    );
  };

  render() {
    if (this.state.smsSent) return this.renderSuccess();

    let rightHeading;
    let leftHeading;

    if (this.props.mode === MODE_EVENT) {
      rightHeading = (
        <span className="heading">Register below to join this event...</span>
      );
      leftHeading = (
        <span className="heading">
          This event runs via
          <br />
          the Find a Player app
        </span>
      );
    } else if (this.props.mode === MODE_PLAYER) {
      rightHeading = (
        <span className="heading">Register below to message the organiser</span>
      );
      leftHeading = (
        <span className="heading">
          All our comms run through
          <br />
          the Find a Player app
        </span>
      );
    } else {
      rightHeading = (
        <span className="heading">Register below to join this club...</span>
      );
      leftHeading = (
        <span className="heading">
          This club runs via
          <br />
          the Find a Player app
        </span>
      );
    }

    let buttonStyle;
    let buttonText;

    if (!this.state.branchLoaded) {
      if (this.state.errorMessage) {
        buttonStyle = "retryButton";
        buttonText = "Retry";
      } else {
        buttonStyle = "loadingButton";
        buttonText = "Initialising...";
      }
    } else {
      if (this.state.isSendingSms) {
        buttonStyle = "sendingButton";
        buttonText = "Sending SMS...";
      } else {
        buttonStyle = "defaultButton";
        buttonText = "Send It!";
      }
    }

    return (
      <div id="fp-signup">
        <div className="left">
          {leftHeading}
          <div className="anim" ref={(ref) => (this.animBox = ref)} />
        </div>
        <div className="divider" />
        <div className="right">
          {rightHeading}
          <div className="form">
            <div className="name-input">
              <div className="field">
                <span className="title">First Name</span>
                <div className="data-input">
                  <input
                    ref={this.firstNameRef}
                    data-key="firstName"
                    onChange={this.saveInput}
                    defaultValue={store.get("firstName")}
                    type="text"
                  ></input>
                  <span className="error">{this.state.errors.firstName}</span>
                </div>
              </div>

              <div className="field">
                <span className="title">Surname</span>
                <div className="data-input">
                  <input
                    ref={this.surnameRef}
                    data-key="surname"
                    onChange={this.saveInput}
                    defaultValue={store.get("surname")}
                    type="text"
                  ></input>
                  <span className="error">{this.state.errors.surname}</span>
                </div>
              </div>
            </div>

            <div className="field">
              <span className="title">Mobile No</span>
              <div className="data-input">
                {this.renderMobileInput()}
                <span className="error">{this.state.errors.mobileNumber}</span>
              </div>
            </div>

            <div className="field">
              <span className="title">Date Of Birth</span>
              <div className="data-input">
                <input
                  style={{ minWidth: "95%" }}
                  ref={this.dobRef}
                  data-key="dob"
                  onChange={this.saveInput}
                  defaultValue={store.get("dob")}
                  type="date"
                ></input>
                {this.state.errors.dob ? (
                  <span className="error">{this.state.errors.dob}</span>
                ) : (
                  <span className="dob">(Over 18s only - sorry!)</span>
                )}
              </div>
            </div>

            <div className="action-container">
              {appContext.isMobile() ? (
                <button className="skip" onClick={this.gotAppClicked}>
                  Got App?
                </button>
              ) : (
                <span></span>
              )}

              <button className={buttonStyle} onClick={this.sendClicked}>
                {buttonText}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
