import appContext from "@/AppContext.js";
import EventListItem from "@/components/main/results/EventListItem.jsx";
import Results from "@/components/main/results/Results.jsx";
import UserListItem from "@/components/main/results/UserListItem.jsx";
import { ReactComponent as PinIcon } from "@/images/pin.svg";
import {
  DEFAULT_AVATAR,
  DEFAULT_CLUB_AVATAR,
  MODE_EVENT,
  MODE_CLUB,
  MODE_PLAYER,
  MODE_IMIN_FACILITY,
  DEFAULT_FACILITY_AVATAR, MODE_IMIN_EVENT,
} from "@/models/Constants";
import "@/styles/common.scss";
import { getVar, isEmpty } from "@/utils.js";
import Modal from "@/views/Modal.jsx";
import React, { PureComponent } from "react";
import { Cluster, Marker, ZoomControl } from "react-mapbox-gl";
import OrgPopup from "./OrgPopup.jsx";
import ClusterPopup from "./ClusterPopup.jsx";
import EventPopup from "./EventPopup.jsx";
import "./styles/Map.scss";
import UserPopup from "./UserPopup.jsx";
import FacilityPopup from "@/components/main/map/FacilityPopup";
import IminEventPopup from "@/components/main/map/IminEventPopup";

export default class Map extends PureComponent {
  constructor(props) {
    super(props);

    const center = props.center;

    this.state = {
      zoom: this.props.zoom,
      center: center,
      data: null,
      cluster: null,
      mode: props.mode,
      shouldSearchOnDragEnd: true,
    };

    this.clusterRef = React.createRef();
    const pinWidth = 35;
    const pinHeight = (pinWidth * 38) / 27;

    this.pinStyle = {
      display: "flex",
      justifyContent: "center",
      position: "realtive",
      width: pinWidth + "px",
      height: pinHeight + "px",
    };
    this.sportIconWidthPx = 0.63 * pinWidth + "px";
    this.top = pinHeight * 0.0875 + "px";
    //this.left = (pinWidth / 5) + "px"

    this.iconStyle = {
      width: this.sportIconWidthPx,
      height: this.sportIconWidthPx,
      marginTop: this.top,
      borderRadius: "50%",
    };
  }
  /*
  componentDidUpdate(prevProps) {
    if(this.props.data !== prevProps.data) {
      this.setState({data:null, cluster: null})
    }
  }
*/
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.mode !== prevState.mode)
      return { ...prevState, data: null, cluster: null, mode: nextProps.mode };
    return null;
  }

  getZoom() {
    return this.map ? this.map.getZoom() : this.props.zoom;
  }

  onClusterClicked = (coords, pointCount, getLeaves) => {
    const loc = { lng: coords[0], lat: coords[1] };
    let zoom = this.getZoom();
    zoom = zoom < 12 ? zoom + 1 : zoom;
    const cluster =
      zoom < 12 ? null : { ...loc, count: pointCount, leaves: getLeaves };
    this.setState(
      {
        zoom: zoom,
        data: null,
        cluster: cluster,
      },
      () => {
        this.props.onMarkerClick(loc, zoom);
      }
    );
  };

  onMarkerClicked = (data) => {
    if (this.props.noClick) return;
    const coords = data.getCoordinates();
    this.setState({ data: data, cluster: null }, () => {
      this.props.onMarkerClick(coords, this.getZoom());
    });
  };

  renderClusterMarker = (coordinates, pointCount, getLeaves) => {
    const id = getLeaves()[0].props.data.getId();
    const mode = this.state.mode;
    const classNames = `fp-unselectable ${
      mode === MODE_EVENT || mode == MODE_CLUB || mode == MODE_IMIN_FACILITY || mode == MODE_IMIN_EVENT
        ? "event-cluster-pin"
        : "user-cluster-pin"
    }`;
    return (
      <Marker
        key={id}
        coordinates={coordinates}
        className={classNames}
        style={this.pinStyle}
        onClick={() =>
          this.onClusterClicked(coordinates, pointCount, getLeaves)
        }
      />
    );
  };

  onMapLoaded = (map) => {
    this.map = map;
    this.forceUpdate();
  };

  renderUserMarker = (user) => {
    const loc = user.getCoordinates();
    const avatarUrl = user.getAvatarUrl();
    const avatarSrc =
      isEmpty(avatarUrl) || avatarUrl.endsWith("default_player.png")
        ? DEFAULT_AVATAR
        : avatarUrl;

    if (this.props.pinOnly) {
      return (
        <Marker
          key={user.getId()}
          className="fp-unselectable"
          coordinates={[loc.lng, loc.lat]}
        >
          <PinIcon stroke={getVar("--fp-player-map-pin-color")} />
        </Marker>
      );
    }

    return (
      <Marker
        key={user.getId()}
        className="fp-unselectable"
        onClick={() => this.onMarkerClicked(user)}
        data={user}
        coordinates={[loc.lng, loc.lat]}
      >
        <img
          className="user-pin"
          src={avatarSrc}
          alt=""
          onError={(e) => {
            e.target.src = DEFAULT_AVATAR;
          }}
        />
      </Marker>
    );
  };

  renderEventMarker = (event) => {
    const loc = event.getCoordinates();
    const sportIconStyle = {
      background: event.event.sport.icon_background_colour,
      ...this.iconStyle,
    };
    return (
      <Marker
        onMouseEnter={this.onMouseEnter}
        key={event.getId()}
        className="pin fp-unselectable"
        onClick={() => this.onMarkerClicked(event)}
        style={this.pinStyle}
        data={event}
        coordinates={[loc.lng, loc.lat]}
      >
        <img src={event.event.sport.icon_url} style={sportIconStyle} alt="" />
      </Marker>
    );
  };

  renderClubMarker = (club) => {
    const loc = club.getCoordinates();
    const avatarUrl = club.avatarUrl;
    const avatarSrc =
      isEmpty(avatarUrl) || avatarUrl.endsWith("default_player.png")
        ? DEFAULT_CLUB_AVATAR
        : avatarUrl;

    if (this.props.pinOnly) {
      return (
        <Marker
          key={club.getId()}
          className="fp-unselectable"
          coordinates={[loc.lng, loc.lat]}
        >
          <PinIcon stroke={getVar("--fp-club-map-pin-color")} />
        </Marker>
      );
    }

    return (
      <Marker
        key={club.getId()}
        className="fp-unselectable"
        onClick={() => this.onMarkerClicked(club)}
        data={club}
        coordinates={[loc.lng, loc.lat]}
      >
        <img
          className="user-pin"
          src={avatarSrc}
          alt=""
          onError={(e) => {
            e.target.src = DEFAULT_CLUB_AVATAR;
          }}
        />
      </Marker>
    );
  };

  renderFacilityMarker = (facility) => {
    const loc = facility.getCoordinates();
    const avatarUrl = facility.imageSrc;
    const avatarSrc =
      isEmpty(avatarUrl)
        ? DEFAULT_FACILITY_AVATAR
        : avatarUrl;

    if (this.props.pinOnly) {
      return (
        <Marker
          key={facility.getId()}
          className="fp-unselectable"
          coordinates={[loc.lng, loc.lat]}
        >
          <PinIcon stroke={getVar("--fp-facility-map-pin-color")} />
        </Marker>
      );
    }

    return (
      <Marker
        key={facility.getId()}
        className="fp-unselectable"
        onClick={() => this.onMarkerClicked(facility)}
        data={facility}
        coordinates={[loc.lng, loc.lat]}
      >
        <img
          className="user-pin"
          src={avatarSrc}
          alt=""
          onError={(e) => {
            e.target.src = DEFAULT_CLUB_AVATAR;
          }}
        />
      </Marker>
    );
  };

  renderIminEventMarker = (imin_event) => {
    const loc = imin_event.getCoordinates();
    const avatarUrl = imin_event.imageSrc;
    const avatarSrc =
      isEmpty(avatarUrl)
        ? DEFAULT_FACILITY_AVATAR
        : avatarUrl;

    if (this.props.pinOnly) {
      return (
        <Marker
          key={imin_event.getId()}
          className="fp-unselectable"
          coordinates={[loc.lng, loc.lat]}
        >
          <PinIcon stroke={getVar("--fp-imin-map-pin-color")} />
        </Marker>
      );
    }

    return (
      <Marker
        key={imin_event.getId()}
        className="fp-unselectable"
        onClick={() => this.onMarkerClicked(imin_event)}
        data={imin_event}
        coordinates={[loc.lng, loc.lat]}
      >
        <img
          className="user-pin"
          src={avatarSrc}
          alt=""
          onError={(e) => {
            e.target.src = DEFAULT_CLUB_AVATAR;
          }}
        />
      </Marker>
    );
  };

  renderPopup = () => {
    const data = this.state.data;
    if (!data) return null;
    const mode = this.props.mode;

    if (mode === MODE_EVENT)
      return (
        <EventPopup event={data} onClick={() => this.props.onClick(data)} />
      );
    else if (mode === MODE_PLAYER)
      return <UserPopup user={data} onClick={() => this.props.onClick(data)} />;
    else if (mode === MODE_IMIN_FACILITY)
      return <FacilityPopup facility={data} onClick={() => this.props.onClick(data)} />;
    else if (mode === MODE_IMIN_EVENT)
      return <IminEventPopup event={data} onClick={() => this.props.onClick(data)} />;
    else
      return <OrgPopup club={data} onClick={() => this.props.onClick(data)} />;
  };

  renderClusterPopup = () => {
    const cluster = this.state.cluster;
    if (!cluster) return null;
    return (
      <ClusterPopup
        cluster={cluster}
        onZoom={() =>
          this.setState({ cluster: null }, () => {
            this.props.onMarkerClick(this.props.center, this.props.zoom + 1);
          })
        }
        onShowAll={() =>
          appContext.showModal("cluster", this.renderClusterData)
        }
      />
    );
  };

  renderEventItem = (event) => {
    const style = {
      padding: "0.5em",
      margin: "0.25em 0.5em",
    };
    return (
      <div style={style}>
        <EventListItem key={event.getId()} event={event} />
      </div>
    );
  };

  renderUserItem = (user) => {
    return <UserListItem key={user.getId()} user={user} />;
  };

  renderClusterData = () => {
    if (!this.state.cluster) return;

    const spanStyle = {
      backgroundColor: "white",
      display: "block",
      padding: "0.5em",
      marginBottom: "0.5em",
      width: "100%",
      fontSize: "medium",
      fontWeight: "bold",
    };

    const style = {
      backgroundColor: "#f5f5f5",
      padding: "0.5em",
      height: "auto",
      maxHeight: "80%",
      overflow: "auto",
    };

    const data = this.state.cluster.leaves().map((item) => item.props.data);
    let text_type = '';
    switch (this.state.mode) {
      case MODE_EVENT:
      case MODE_IMIN_EVENT:
        text_type = 'Event(s)'
        break;
      case MODE_IMIN_FACILITY:
        text_type = 'Facilities'
        break;
      default:
        text_type = 'Player(s)'
    }
    const text = `${data.length} ${text_type} in this area`;

    return (
      <Modal name="cluster" showClose style={style}>
        <div>
          <span style={spanStyle}>{text}</span>
          <Results
            res={this.props.res}
            modal={true}
            data={data}
            mode={this.state.mode}
            onClick={this.props.onClick}
          />
        </div>
      </Modal>
    );
  };

  onDragEnd = (map, event) => {
    if (this.state.shouldSearchOnDragEnd && this.props.onUpdate)
      this.props.onUpdate(map, event);
  };

  onInputChange = (e) => {
    const input = e.currentTarget;
    this.setState({ shouldSearchOnDragEnd: input.checked });
  };

  getMarkerRenderer = () => {
    const mode = this.state.mode;
    if (mode === MODE_EVENT) return this.renderEventMarker;
    else if (mode === MODE_PLAYER) return this.renderUserMarker;
    else if (mode === MODE_IMIN_FACILITY) return this.renderFacilityMarker;
    else if (mode === MODE_IMIN_EVENT) return this.renderIminEventMarker;
    else return this.renderClubMarker;
  };

  getFilteredData = () => {
    const mode = this.props.mode;
    const data = this.props.data;
    if (mode === MODE_EVENT) return data;
    else if (mode === MODE_PLAYER)
      return data.filter((user) => user.getCoordinates());
    else if (mode === MODE_IMIN_FACILITY) return data;
    else if (mode === MODE_IMIN_EVENT) return data;
    else return data.filter((club) => club.location);
  };

  render() {
    const renderMarker = this.getMarkerRenderer();

    const data = this.getFilteredData();
    const markers = data.map((item) => renderMarker(item));

    /*
    const mapStyle = getComputedStyle(document.documentElement)
                     .getPropertyValue("--fp-map-style")
                     .trim()
                     .slice(1,-1)
    */

    let mapStyle = getVar("--fp-map-style").trim();
    mapStyle = mapStyle.substring(1, mapStyle.length - 1);

    return (
      <div style={{ height: "100%", position: "relative" }}>
        <this.props.instance
          style={mapStyle}
          center={this.props.center}
          zoom={[this.props.zoom]}
          onDragEnd={this.onDragEnd}
          onStyleLoad={this.onMapLoaded}
          onClick={() => {
            this.setState({ cluster: null, data: null });
          }}
          movingMethod="flyTo"
          flyToOptions={{ essential: true, speed: 2 }}
          containerStyle={{
            flexGrow: "1",
            height: "100%",
          }}
        >
          {this.props.showZoom ? <ZoomControl style={{ zIndex: 4 }} /> : null}
          <Cluster
            ClusterMarkerFactory={this.renderClusterMarker}
            radius={40}
            maxZoom={24}
          >
            {markers}
          </Cluster>

          {this.state.data ? this.renderPopup() : null}

          {this.state.cluster ? this.renderClusterPopup() : null}
        </this.props.instance>
        {this.props.onUpdate && this.map ? (
          <div className="search-move-container fp-shadow">
            <label>
              <input
                type="checkbox"
                checked={this.state.shouldSearchOnDragEnd}
                onChange={this.onInputChange}
              />
              Search on map move
            </label>
          </div>
        ) : null}
        {this.map && this.props.mode === MODE_CLUB ? (
          <a
            target="_blank"
            href="https://findaplayer.typeform.com/clubs"
            className="fp-club-signup-button"
          >
            Sign Up
          </a>
        ) : null}
      </div>
    );
  }
}

Map.defaultProps = {
  interactive: true,
  zoom: 16,
  showZoom: true,
  data: [],
};
