import appContext from "@/AppContext"
import "@/styles/common.scss"
import {isEmpty} from "@/utils.js"
import DataView from "@/views/DataView.jsx"
import React, {PureComponent} from "react"
import "./styles/Filter.scss"
import {formatTime} from "@/time";

export default class TimeRangeFilter extends PureComponent {

  onChange = (e, target, input) => {
    const value = e.target.value ? parseInt(e.target.value) : null;

    let time_ranges = Object.assign({}, this.props.filter)
    time_ranges[target][input] = value;

    this.props.onChange({time_ranges: time_ranges})
  }

  getTime = (times) => {
    if (
      times.from.hour === null && times.from.minute === null
      && times.to.hour === null && times.to.minute === null
    ) return "Any time"

    const from_date = new Date();
    from_date.setHours(times.from.hour ?? null)
    from_date.setMinutes(times.from.minute ?? 0)
    const from_time = formatTime(from_date)

    const to_date = new Date();
    to_date.setHours(times.to.hour ?? null)
    to_date.setMinutes(times.to.minute ?? 0)
    const to_time = formatTime(to_date)

    let time_range = '';

    if (times.from.hour !== null) {
      time_range += from_time;
    }
    if (times.to.hour !== null) {
      time_range += times.from.hour !== null ? ' - ' : '';
      time_range += to_time;
    }

    return time_range;
  }


  renderMenu = () => {
    return (
      <div className="fp-dropdown filter-box filter-time-ranges">
        <div className="filter">
          <DataView
            style={this.props.style}
            title="Time"
            subtitle={this.getTime(this.props.filter)}
            hideIcon/>
        </div>
        <div className="fp-dropdown-box">
          {this.renderOptions()}
        </div>
      </div>
    )
  }

  renderHourOptions = (val) => {
    const el = [];

    for (let hr = 0; hr < 24; hr++) {
      el.push(
        <option key={hr} value={hr}>{hr.toString().padStart(2, '0')}</option>
      )
    }

    return el;
  }

  renderMinutesOptions = (val) => {
    const el = [];

    for (let min = 0; min < 60; min++) {
      el.push(
        <option key={min} value={min}>{min.toString().padStart(2, '0')}</option>
      )
    }

    return el;
  }

  renderMobile = () => {
    return (
      <div className="fp-dropdown-box filter-time-ranges mobile">
        {this.renderOptions()}
      </div>
    );
  }

  renderOptions = () => {
    const from_hr_options = this.renderHourOptions(this.props.filter.from.hour);
    const to_min_options = this.renderMinutesOptions(this.props.filter.from.minute);
    const hr_options = this.renderHourOptions(this.props.filter.to.hour);
    const min_options = this.renderMinutesOptions(this.props.filter.to.minute);

    return (
      <div className="filter-options fp-shadow">
        <div className="fp-option-container fp-unselectable">
          From: {this.props.filter.from.hour}
          <div className="time-options-container">
            <select name="" id="" value={this.props.filter.from.hour ?? ''} onChange={event => this.onChange(event, 'from', 'hour')}>
              <option value=''>HH</option>
              {from_hr_options}
            </select>
            <span className="sep">:</span>
            <select name="" id="" value={this.props.filter.from.minute ?? ''} onChange={event => this.onChange(event, 'from', 'minute')}>
              <option value=''>MM</option>
              {to_min_options}
            </select>
          </div>
        </div>
        <div className="fp-option-container fp-unselectable">
          To:
          <div className="time-options-container">
            <select name="" id="" value={this.props.filter.to.hour ?? ''} onChange={event => this.onChange(event, 'to', 'hour')}>
              <option value=''>HH</option>
              {hr_options}
            </select>
            <span className="sep">:</span>
            <select name="" id="" value={this.props.filter.to.minute ?? ''} onChange={event => this.onChange(event, 'to', 'minute')}>
              <option value=''>MM</option>
              {min_options}
            </select>
          </div>
        </div>
      </div>
    )
  }

  render() {
    const elements = appContext.isMobile() ?
      this.renderMobile() :
      this.renderMenu()

    return elements
  }
}

TimeRangeFilter.defaultProps = {
  filter: []
}
