import React from "react";
import "./styles/Option.scss";
import "@/styles/common.scss";

export default function Option(props) {
  return (
    <label className="fp-option-container fp-unselectable" htmlFor={props.id}>
      {props.text}
      <input
        id={props.id}
        name={props.name}
        type={props.type}
        value={props.value}
        checked={props.checked}
        onChange={props.onChange}
      />
    </label>
  );
}

Option.defaultProps = {
  type: "radio",
};
