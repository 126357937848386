import appContext from "@/AppContext.js";
import { ReactComponent as CrossIcon } from "@/images/cross.svg";
import "@/styles/common.scss";
import React from "react";
import "./styles/Modal.scss";

export default class Modal extends React.PureComponent {
  onClose = () => {
    appContext.closeModal(this.props.name, this.props.onClose);
  };

  render() {
    return (
      <div key={this.props.name} className="fp-modal" onClick={this.onClose}>
        <div
          style={{ ...this.props.style }}
          onClick={(e) => e.stopPropagation()}
        >
          {this.props.children}
          {this.props.showClose ? (
            <div className="fp-close-button" onClick={this.onClose}>
              <CrossIcon className="fp-center" width={14} height={14} />
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}
