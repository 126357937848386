/**
 * Fix Amenities names
 *
 * @param amenities
 * @returns {*[]}
 */
export function amenitiesNames(amenities = []) {
  return amenities.map(amenity => {
    amenity.name = amenity.name.replace('beta:', '');

    // Add space between two capital letters
    // e.g. ChangingFacilities -> Changing Facilities
    amenity.name = amenity.name.replace(/([A-Z])/g, ' $1').trim();

    return amenity
  });
}