class Store {
  constructor() {
    this.hasLocalStorage = typeof(Storage) !== "undefined"
  }

  get(key, defaultValue=null) {
    if(!this.hasLocalStorage) return defaultValue
    const v = localStorage.getItem(key)
    if(!v) return defaultValue
    return v
  }

  set(key,value) {
    if(!this.hasLocalStorage) return
    localStorage.setItem(key,value)
  }

  getInt(key, defaultValue=0) {
    const v = this.get(key)
    if(!v) return defaultValue
    return parseInt(v,10)
  }

  setInt(key,value) {
    this.set(key,value.toString())
  }
}

const store = new Store()
export default store