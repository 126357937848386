import React from "react";
import { formatDate, formatTime } from "@/time.js";
import { Popup } from "react-mapbox-gl";

import "./styles/EventPopup.scss";
import "@/styles/common.scss";

export default function EventPopup(props) {
  const event = props.event;
  const sport = event.getSport();
  const loc = event.getCoordinates();
  const startDate = formatDate(event.getStartTime());
  const startTime = formatTime(event.getStartTime());
  const cost = event.getFormattedCost();

  return (
    <Popup
      coordinates={[loc.lng, loc.lat]}
      anchor="top"
      onClick={props.onClick}
    >
      <div className="event-popup-container fp-unselectable fp-shadow">
        <img
          src={sport.getIconUrl()}
          style={{ backgroundColor: sport.getBackgroundColor() }}
          alt=""
        />
        <span className="name">{event.getName()}</span>
        <span className="date">{`${startDate} ${startTime}`}</span>
        <div className="fp-separator" style={{ margin: "0.5em 0" }}></div>
        <span className="cost">{cost}</span>
        <span className="join">Join</span>
      </div>
    </Popup>
  );
}
