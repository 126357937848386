import appContext from "@/AppContext"
import Sports from "@/models/Sports.js"
import "@/styles/common.scss"
import DataView from "@/views/DataView.jsx"
import Option from "@/views/Option.jsx"
import React from "react"
import "./styles/Filter.scss"

export default class SportFilter extends React.Component {
  
  onChange = (e) => {
    const el = e.currentTarget
    //const id = el.id
    const value = parseInt(el.value)
    let sports = this.props.filter
    if(el.checked) sports.push(value)
    else sports = sports.filter(id => id !== value)
    this.props.onChange({sports:sports})
  }

  renderMenu = (options) => {
    const sports = this.props.filter
    const selectableSports = Sports.getSelectable()
    const isOnlySingleSport = selectableSports.length === 1
    const subtitle = isOnlySingleSport ? 
                     selectableSports[0].getName() : 
                     (sports.length === 0 ? "All sports" : `${sports.length} selected`)
    return (
      <div className="fp-dropdown filter-box">
        <div className="filter">
          <DataView  
            style={this.props.style} 
            title="Sports" 
            subtitle={subtitle} 
            hideIcon />
        </div>
        <div className="fp-dropdown-box">
          <div className="filter-options shadow">
            {isOnlySingleSport ? null : options}
          </div>
        </div>
      </div>
    )
  }

  renderOptions = (options) => {
    return (
      <div>{options}</div>
    )
  }

  render() {
    const filter = this.props.filter
    const options = Sports.getSelectable().map(
      sport => (
        <Option
          type="checkbox"
          key={sport.getName()}
          id={sport.getName()}
          value={sport.getId()}
          text={sport.getName()}
          onChange={this.onChange}
          checked={filter.find(id => id === sport.getId())!==undefined}/>
      )
    )

    const elements = appContext.isMobile() ?
        this.renderOptions(options) :
        this.renderMenu(options)

    return elements
  }

}