import { formatCurrency } from "@/utils";
import FacilitySlot from "@/models/FacilitySlot";
import { formatDate } from "@/time";
import { DEFAULT_FACILITY_AVATAR } from '@/models/Constants';
import {amenitiesNames} from "@/iminUtils";

export default class Facility {
    constructor(facility) {
        this.facility = facility;
        const { location, name, provider } = facility
        const { image, address, description, amenityFeature } = location

        this.name = name
        this.description = description ?? provider?.description ?? ''
        this.imageSrc = image
            ? image[0].url
            : DEFAULT_FACILITY_AVATAR;

        this.location = location;
        this.address = address['imin:fullAddress']

        this.amenities = amenityFeature;
        this.aggregatePrice = facility['imin:aggregateOffer']
            ? facility['imin:aggregateOffer'].publicAdult
            : null;
    }

    getId() {
        return this.facility.id || null;
    }

    getAddress() {
        return this.address
            ? this.address
            .split(',')
            .slice(1)
            .filter(item => item !== '')
            .map(item => item.trim())
            .join(', ')
            : '';
    }

    getAmenities() {
        const amenities = this.amenities?.filter(item => item.value) || []
        return amenitiesNames(amenities);
    }

    getSlots() {
        return this.facility?.event?.map(item => new FacilitySlot(item))
            .sort((a,b) => {
                return (a.slot.startDate > b.slot.startDate) ? 1 : -1
            })
          || [];
    }

    getDate() {
        return this.getSlots()[0]?.slot?.startDate || false
    }

    getFormattedDate() {
        const date = this.getDate();
        return date
            ? formatDate(new Date(date))
            : null;
    }

    getCoordinates() {
        return {
            lat: this.location.geo.latitude,
            lng: this.location.geo.longitude
        }
    }

    getFormattedCost() {
        return this.aggregatePrice
          ? this.aggregatePrice.price !== 0
            ? formatCurrency(this.aggregatePrice.price, this.aggregatePrice.priceCurrency)
            : 'FREE'
          : null;
    }
}
