import React from "react";
import "./styles/Button.scss";
import "@/styles/common.scss";
import classNames from "classnames";

export default function Button(props) {
  const style = props.style;
  const classes = classNames("fp-button", props.shadow ? "fp-shadow" : "");
  return (
    <div className={classes} style={style.container}>
      {props.icon}
      <span style={style.text}>{props.text}</span>
    </div>
  );
}

Button.defaultProps = {
  text: "OK",
  style: {
    container: {
      padding: "0.5em 1.5em",
    },
    text: {
      fontSize: "medium",
    },
  },
};
