import {formatCurrency} from "@/utils";
import dayjs from "dayjs";
import advanceFormat from "dayjs/plugin/advancedFormat"
dayjs.extend(advanceFormat)

export default class IminEventSlot {
  constructor(slot) {
    this.slot = slot;
  }

  getDateTime() {
    return new Date(this.slot.startDate);
  }

  getFormattedDate() {
    const datetime = dayjs(this.slot.startDate);

    return datetime.format('ddd Do MMM');
  }

  getFormattedTime() {
    const start_time = dayjs(this.slot.startDate);
    const end_time = dayjs(this.slot.endDate);

    return start_time.format('h:mma') + ' (' + this.getDuration() + ')';
  }

  getDuration() {
    return this.slot.duration?.replace('PT', '') ?? ''
  }
}
