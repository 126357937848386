import { ReactComponent as PersonIcon } from "@/images/person.svg";
import "@/styles/common.scss";
import { formatDate, formatTime } from "@/time.js";
import React from "react";
import "./styles/EventListItem.scss";
import "./styles/Results.scss";

export default class EventListItem extends React.PureComponent {
  joinClicked = () => {
    this.props.onClick(this.props.event);
  };

  render() {
    const event = this.props.event;
    const play = event.getPlay();
    const startDate = formatDate(event.getStartTime());
    const startTime = formatTime(event.getStartTime());
    const endTime = formatTime(event.getEndTime());
    const cost = event.getFormattedCost();
    const description = event.getDescription("No description");
    //const maxPlayers = play.getMaxPlayers()
    const openSpots = play.showAsOpen()
      ? "Open"
      : `${play.getSpotsFilled()} / ${play.getMaxPlayers()}`;

    const sport = event.getSport();
    const sportStyle = { backgroundColor: sport.getBackgroundColor() };

    return (
      <div className="item">
        <div className="event-container">
          <div className="cell">
            <div className="s-2" style={{ overflow: "hidden" }}>
              <span className="title">{event.getName()}</span>
            </div>

            <div className="s-1" style={{ textAlign: "right" }}>
              <span className="time">{startDate}</span>
              <br />
              <span className="time">
                {startTime} - {endTime}
              </span>
            </div>
          </div>
          <div className="divider"></div>
          <div style={{ display: "none" }}>
            <div style={{ height: "160px", backgroundColor: "darkgrey" }}></div>
            <div className="cell">
              <div className="s-1">Male only</div>
              <p className="s-2" style={{ textAlign: "end" }}>
                Last active 7 days ago
              </p>
            </div>
            <div className="pill">
              Run by | <b>Find a Player</b>
            </div>
          </div>

          <div className="cell description">
            <span className="fp-clamp-2">{description}</span>
          </div>

          <div className="cell">
            <div className="sport">
              <img alt="" src={sport.getIconUrl()} style={sportStyle} />
            </div>
            <div className="cost s-1">{cost}</div>

            <div className="s-1">
              <label style={{ textAlign: "center" }}></label>
            </div>

            <div className="more-details">
              <div>
                <PersonIcon />
                <span>{openSpots}</span>
              </div>
              <button className="fp-shadow" onClick={this.joinClicked}>
                More Details
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
