import Api from "@/api/Api";
import appContext from "@/AppContext.js";
import {ReactComponent as FilterIcon} from "@/images/cross.svg";
import {
  MODE_CLUB,
  MODE_EVENT,
  MODE_IMIN_EVENT,
  MODE_IMIN_FACILITY,
  MODE_LIST,
  MODE_MAP,
  MODE_PLAYER,
  PER_PAGE,
  TYPE_MAP,
  TYPE_NEARBY,
  TYPE_PLACE,
} from '@/models/Constants.js';
import Event from "@/models/Event.js";
import User from "@/models/User.js";
import "@/styles/common.scss";
import {
  currentLocation,
  getLocationFromFilter,
  getModeText,
  isEmpty,
  isHidden,
  isValidLocation,
  log,
  makeFilter,
} from "@/utils";
import Disableable from "@/views/Disableable.jsx";
import Loader from "@/views/Loader.jsx";
import Modal from "@/views/Modal.jsx";
import Toast from "@/views/Toast.jsx";
import classNames from "classnames";
import React from "react";
import ReactMapboxGl from "react-mapbox-gl";
import Filters from "./filter/Filters.jsx";
import SearchModeFilter from "./filter/SearchModeFilter.jsx";
import Map from "./map/Map.jsx";
import Results from "./results/Results.jsx";
import "./styles/Main.scss";
import TagManager from "react-gtm-module";
import Organisation from "@/models/Organisation.js";
import ApiImin from '@/api/ApiImin';
import Facility from "@/models/Facility";
import IminEvent from "@/models/IminEvent";
import IminResponse from "@/models/IminResponse";

let Mapbox = ReactMapboxGl({
  accessToken: appContext.getMapboxToken(),
  interactive: true,
});

export default class Main extends React.PureComponent {
  constructor(props) {
    super(props);
    let location = getLocationFromFilter(props.filter.location);
    if (!isValidLocation(location)) location = props.config.defaultLocation;

    this.state = {
      filter: {...props.filter},
      results: {
        events: [],
        players: [],
        clubs: [],
        facilities: [],
        imin_events: [],
        imin_response: null,
        current_page: null,
        total_pages: 0,
        total_items: 0
      },
      map: {
        lat: location.lat,
        lng: location.lng,
        zoom: location.zoom || 16,
      },
      isSearching: false,
      message: null,
      mobileDisplayMode: MODE_LIST,
    };

    if (props.config.gtmId) {
      TagManager.initialize({gtmId: props.config.gtmId});
    }
  }

  componentDidUpdate(oldProps, oldState) {
    const data = this.getDataForMode(this.props.mode);

    if (oldProps.mode !== this.props.mode)
      this.setState({message: null}, () => {
        if (data.length === 0 && !this.state.isSearching) this.search();
      });
  }

  componentDidMount() {
    if (this.nearbyAndNoLocation()) {
      this.getCurrentLocation();
    } else {
      const loc = getLocationFromFilter(this.state.filter.location);
      if (loc.lat !== 0 && loc.lng !== 0) {
        this.search();
      }
    }
  }

  getDataForMode = (mode) => {
    if (mode === MODE_EVENT) return this.state.results.events;
    else if (mode === MODE_PLAYER) return this.state.results.players;
    else if (mode === MODE_IMIN_FACILITY) return this.state.results.facilities;
    else if (mode === MODE_IMIN_EVENT) return this.state.results.imin_events;
    else return this.state.results.clubs;
  };

  onCurrentLocationReceived = (position) => {
    let location = {
      lat: position.coords.latitude,
      lng: position.coords.longitude,
      zoom: 14,
    };
    const map =
      this.state.filter.location.type === TYPE_NEARBY
        ? location
        : this.state.map;

    this.setState(
      (state) => ({
        message: "Location found",
        map: map,
        filter: {
          ...state.filter,
          location: {...state.filter.location, nearby: location},
        },
      }),
      () => {
        this.search();
      }
    );
  };

  onCurrentLocationError = (error) => {
    if (error && error.message)
      this.setState({message: "Enable location to search nearby"});
  };

  search = (page = 1) => {
    const mode = this.props.mode;
    if (mode === MODE_EVENT) this.getEvents();
    else if (mode === MODE_PLAYER) this.getPlayers();
    else if (mode === MODE_IMIN_FACILITY) this.getFacilities(page);
    else if (mode === MODE_IMIN_EVENT) this.getIminEvents(page);
    else this.getClubs();
  };

  getSearchState = (results) => {
    const mode = this.props.mode;
    if (mode === MODE_EVENT) return {...this.state.results, events: results};
    else if (mode === MODE_PLAYER)
      return {...this.state.results, players: results};
    else if (mode === MODE_IMIN_FACILITY)
      return {...this.state.results, facilities: results};
    else if (mode === MODE_IMIN_EVENT)
      return {...this.state.results, imin_events: results};
    else return {...this.state.results, clubs: results};
  };

  getFilter = () => {
    return makeFilter(this.props.mode, this.props.config, this.state.filter);
  };

  getPlayers = () => {
    this.setState({isSearching: true, message: null}, () => {
      Api.getPlayers(this.getFilter(), (res, err) => {
        if (err) {
          this.setState({
            isSearching: false,
            message: "Error occurred - " + JSON.stringify(err),
          });
          return;
        }
        const players = res.people
          .filter((user) => user.session_profile_datas.length > 2)
          .map((user) => new User(user));
        const message =
          res.people.length === 0 ? "No search results found" : null;
        this.setState({
          results: {
            ...this.getSearchState(players),
            current_page: 1,
            total_pages: 1,
            total_items: players.length
          },
          isSearching: false,
          message: message,
        });
      });
    });
  };

  getEvents = () => {
    this.setState({isSearching: true, message: null}, () => {
      Api.getEvents(this.getFilter(), (res, err) => {
        if (err) {
          this.setState({
            isSearching: false,
            message: "Error occurred - " + JSON.stringify(err),
          });
          return;
        }
        const events = res.events.map((e) => new Event(e));
        const message =
          res.events.length === 0 ? "No search results found" : null;
        this.setState({
          results: {
            ...this.getSearchState(events),
            current_page: 1,
            total_pages: 1,
            total_items: events.length
          },
          isSearching: false,
          message: message,
        });
      });
    });
  };

  getClubs = () => {
    this.setState({isSearching: true, message: null}, () => {
      Api.getOrgs(this.getFilter(), (res, err) => {
        if (err) {
          this.setState({
            isSearching: false,
            message: "Error occurred - " + JSON.stringify(err),
          });
        } else {
          const orgs = res.organisations.map((o) => new Organisation(o));
          const message = orgs.length === 0 ? "No search results found" : null;
          this.setState({
            results: {
              ...this.getSearchState(orgs),
              current_page: 1,
              total_pages: 1,
              total_items: orgs.length
            },
            isSearching: false,
            message: message,
          });
        }
      });
    });
  };

  getFacilities = (page) => {
    this.setState({isSearching: true, message: null}, () => {
      const params = this.getFilter();
      params.page = page;

      ApiImin.getFacilities(params, (res, err) => {
        if (err) {
          this.setState({
            isSearching: false,
            message: "Error occurred - " + JSON.stringify(err),
          });
          return;
        }

        const imin_response = new IminResponse(res);
        const message = imin_response.total_items === 0 ? "No search results found" : null;

        let facilities = imin_response.item;
        if (imin_response.current_page > 1) {
          facilities = [...this.state.results.facilities, ...imin_response.item];
        }

        this.setState({
          results: {
            ...this.getSearchState(facilities),
            current_page: imin_response.current_page,
            total_pages: imin_response.total_pages,
            total_items: imin_response.total_items,
          },
          isSearching: false,
          message: message,
        });
      });
    });
  }

  getIminEvents = (page) => {
    this.setState({isSearching: true, message: null}, () => {
      const params = this.getFilter();
      params.page = page;

      ApiImin.getEvents(params, (res, err) => {
        if (err) {
          this.setState({
            isSearching: false,
            message: "Error occurred - " + JSON.stringify(err),
          });
          return;
        }

        const imin_response = new IminResponse(res);
        const message = imin_response.total_items === 0 ? "No search results found" : null;

        let events = imin_response.item;
        if (imin_response.current_page > 1) {
           events = [...this.state.results.imin_events, ...imin_response.item];
        }

        this.setState({
          results: {
            ...this.getSearchState(events),
            current_page: imin_response.current_page,
            total_pages: imin_response.total_pages,
            total_items: imin_response.total_items,
          },
          isSearching: false,
          message: message,
        });
      });
    });
  }

  onMapUpdated = (map, event) => {
    clearTimeout(this.mapMovedTimeout);
    this.mapMovedTimeout = setTimeout(() => {
      this.onFilterUpdate(
        {
          location: {
            ...this.state.filter.location,
            type: TYPE_MAP,
            map: {...map.getCenter(), zoom: map.getZoom()},
          },
        },
        true
      );
    }, 800);
  };

  onFilterUpdate = (filter, doSearch = false) => {
    const searchTermChanged =
      !isEmpty(filter.name) && filter.name !== this.state.filter.name;
    if (!searchTermChanged) filter.name = "";

    let map = {...this.state.map};
    const newFilter = {...this.state.filter, ...filter};
    const {lat, lng, zoom} = filter.location
      ? getLocationFromFilter(newFilter.location)
      : {lat: 0, lng: 0, zoom: 16};

    if (lat !== 0 && lng !== 0) map = {lat, lng, zoom};

    this.setState({map, filter: newFilter}, () => {
      log("Filter updated", this.state.filter);
      if (doSearch) this.onSubmit(searchTermChanged);
    });
  };

  onSubmit = (searchTermChanged) => {
    const {type, place} = this.state.filter.location;
    if (searchTermChanged) this.search();
    else if (this.nearbyAndNoLocation()) this.getCurrentLocation();
    else if (type == TYPE_PLACE && place.lat === 0 && place.lng === 0)
      this.setState({message: "Specified location not found"});
    else this.search();
  };

  nearbyAndNoLocation = () => {
    const {type, nearby} = this.state.filter.location;
    if (isHidden("nearby", this.props.hiddenFields)) return false;
    return type === "nearby" && nearby.lat === 0 && nearby.lng === 0;
  };

  getCurrentLocation = () => {
    currentLocation(
      this.onCurrentLocationReceived,
      this.onCurrentLocationError
    );
  };

  onDisplayModeChanged = (newDisplayMode) => {
    this.setState({mobileDisplayMode: newDisplayMode});
  };

  toast = (message) => {
    setTimeout(() => {
      if (this.state.message !== null) this.setState({message: null});
    }, 3000);
    return <Toast message={this.state.message}/>;
  };

  renderMobileModeSelection = () => (
    <Modal
      style={{width: "80%"}}
      name="mode-selection"
      onClose={() => appContext.closeModal("mode-selection")}
    >
      <SearchModeFilter
        mode={this.props.mode}
        modes={this.props.config.modes}
        onChange={(newMode) => {
          appContext.setMode(newMode);
          appContext.closeModal("mode-selection");
        }}
        isMobile={appContext.isMobile()}
      />
    </Modal>
  );

  renderMobileMode = () => {
    var text = getModeText(this.props.mode, this.props.config.modes);
    return (
      <Disableable disabled={isHidden("mode", this.props.hiddenFields)}>
        <div
          className="mobile-mode"
          onClick={() =>
            appContext.showModal(
              "mode-selection",
              this.renderMobileModeSelection
            )
          }
        >
          {text}
        </div>
      </Disableable>
    );
  };

  onMarkerClicked = (center, zoom) => {
    this.setState({map: {...center, zoom}});
  };

  onClearFiltersClicked = () => {
    this.onFilterUpdate({
      gender: "any", sports: [], days: [], times: [], time_ranges: {
        from: {
          hour: null,
          minute: null
        },
        to: {
          hour: null,
          minute: null
        }
      }
    });
    this.setState({message: "Cleared filters"});
  };

  onLoadMore = () => {
    this.search(this.state.results.current_page + 1);
  };

  getResults = () => {
    const results = this.state.results;
    switch (this.props.mode) {
      case MODE_EVENT:
        return results.events;
      case MODE_PLAYER:
        return results.players;
      case MODE_IMIN_FACILITY:
        return results.facilities;
      case MODE_IMIN_EVENT:
        return results.imin_events;
      default:
        return results.clubs;
    }
  };

  getResultsClass = () => {
    switch (this.props.mode) {
      case MODE_EVENT:
        return "events";
      case MODE_PLAYER:
        return "players";
      default:
        return "clubs";
    }
  };

  getResultsCountText = (results) => {
    var mode = this.props.mode;

    const count_context = {};
    count_context[MODE_EVENT] = ['event', 'events'];
    count_context[MODE_CLUB] = ['club', 'clubs'];
    count_context[MODE_PLAYER] = ['player', 'players'];
    count_context[MODE_IMIN_FACILITY] = ['facility', 'facilities'];
    count_context[MODE_IMIN_EVENT] = ['event', 'events'];

    if ([MODE_IMIN_EVENT, MODE_IMIN_FACILITY].includes(mode)) {
      mode = this.state.results.total_items > 1 ? count_context[mode][1] : count_context[mode][0]

      return `${this.state.results.total_items} ${mode} found`;
    } else {
      mode = results.length > 1 ? count_context[mode][1] : count_context[mode][0]

      return `${this.state.results.total_items}${
        results.length > PER_PAGE ? "+" : ""
      } ${mode} found`;
    }


  };

  render() {
    const {config} = this.props;
    const {mobileDisplayMode, isSearching} = this.state;
    const isEventMode = this.props.mode === MODE_EVENT;
    var results = this.getResults();

    const hideMap = mobileDisplayMode === MODE_LIST && appContext.isMobile();
    const hideList =
      (mobileDisplayMode === MODE_MAP && appContext.isMobile()) ||
      results.length == 0;
    const isFullPage = results.length === PER_PAGE;
    const resultCount = this.getResultsCountText(results);
    const resultClasses = classNames("results", this.props.mode);
    const resultCountClasses = classNames(
      "results-count",
      !isEventMode ? "results-count-players" : ""
    );

    const {lat, lng, zoom} = this.state.map;

    return (
      <div id="fp-main">
        {this.state.message ? this.toast(this.state.message) : null}
        {this.state.isSearching ? <Loader/> : null}
        <div className="filter-bar">
          <Filters
            filter={this.state.filter}
            config={this.props.config}
            onUpdate={this.onFilterUpdate}
            onSubmit={this.onSubmit}
            hiddenFields={config.hiddenFields}
            mobileDisplayMode={this.state.mobileDisplayMode}
            onChangeDisplayMode={this.onDisplayModeChanged}
            mode={this.props.mode}
            form={this.props.form}
          />
        </div>

        <div className={resultCountClasses}>
          <div className="results-count-heading">
            <span>{resultCount}</span>
            {isFullPage ? <span>({PER_PAGE} loaded max)</span> : null}
          </div>
          <div className="clear-filter" onClick={this.onClearFiltersClicked}>
            <FilterIcon/>
            Clear filters
          </div>
          {appContext.isMobile() ? this.renderMobileMode() : null}
        </div>

        <div className="content">
          <Disableable disabled={hideList}>
            <div className={resultClasses}>
              <Results
                searching={isSearching}
                res={this.state.results}
                data={results}
                mode={this.props.mode}
                onClick={this.props.onClick}
                onLoadMore={this.onLoadMore}
              />
            </div>
          </Disableable>
          <Disableable disabled={hideMap}>
            <div className="map">
              <Map
                searching={isSearching}
                res={this.state.results}
                data={results}
                instance={Mapbox}
                interactive={true}
                onClick={this.props.onClick}
                mode={this.props.mode}
                center={{lat, lng}}
                zoom={zoom}
                onUpdate={this.onMapUpdated}
                onMarkerClick={this.onMarkerClicked}
              />
            </div>
          </Disableable>
        </div>
      </div>
    );
  }
}
