import { isEmpty } from "@/utils"

class Sports {

  constructor() {
    this.sports = []
    this.selectableSports = []
  }
  
  init(sports) {
    this.sports = sports
    this.selectableSports = sports
  }

  setSelectable(selectableSports) {
    if(!isEmpty(selectableSports))
      this.selectableSports = selectableSports
  }

  getSelectable() {
    return this.selectableSports
  }

  getAll() {
    return this.sports
  }

  getByIds(ids) {
    if(isEmpty(ids)) return []
    return ids.map(id => this.get(id)).filter(s => s)
  }

  get(id) {
    return this.sports.find(sport => sport.getId() === id)
  }
}

const _Sports = new Sports()
export default _Sports