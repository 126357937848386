import { ReactComponent as CrossIcon } from "@/images/cross.svg"
import {MODE_IMIN_FACILITY, TYPE_NEARBY, TYPE_PLACE} from "@/models/Constants.js"
import Sports from "@/models/Sports.js"
import { getGendersOptions, isEmpty, isHidden } from "@/utils.js";
import DataView from "@/views/DataView.jsx"
import Disableable from "@/views/Disableable.jsx"
import React from "react"
import DayFilter from "./DayFilter.jsx"
import GenderFilter from "./GenderFilter.jsx"
import LocationFilter from "./LocationFilter.jsx"
import SportFilter from "./SportFilter.jsx"
import "./styles/MobileFilter.scss"
import TimeFilter from "./TimeFilter.jsx"
import { MODE_CLUB } from "@/models/Constants.js"
import Tags from "@/models/Tags.js"
import TagFilter from "./TagFilter.jsx"
import TimeRangeFilter from "@/components/main/filter/TimeRangeFilter";
import {formatTime} from "@/time";

export default class MobileFilter extends React.PureComponent {

  constructor(props) {
    super(props)
    this.state = {
      filter: {...props.filter, name:null},
      render: null
    }
  }

  onUpdate = (filter) => {
    this.setState(prevState => ({filter: {...prevState.filter, ...filter} }),
    () => {
      //console.log(this.state.filter)
    })
  }

  renderDays = () => {
    return (
      <DayFilter
        type={this.props.mode === MODE_IMIN_FACILITY ? 'radio' : 'checkbox'}
        mode={this.props.mode}
        filter={this.state.filter.days}
        onChange={this.onUpdate}
        isMobile={true}/>
    )
  }

  renderTime = () => {
    return (
      <TimeFilter
        filter = {this.state.filter.times}
        onChange={this.onUpdate}
        isMobile={true} />
    )
  }

  renderTimeRange = () => {
    return (
      <TimeRangeFilter
        filter={this.state.filter.time_ranges}
        onChange={this.onUpdate}
        isMobile={true}
      />
    )
  }

  renderSports = () => {
    return (
      <SportFilter
        filter = {this.state.filter.sports}
        onChange={this.onUpdate}
        isMobile={true}
      />
    )
  }

  getTags = (type) => {
    return (
      type === Tags.TEAM_LEVEL ? this.state.filter.team_level : this.state.filter.team_type
    )
  }

  renderTags = (title, type) => {
    return (
      <TagFilter
        filter={this.getTags(type)}
        type={type}
        title={title}
        isMobile={true}
        onChange={this.onUpdate}
      />
    )
  }

  renderGender = () => {
    return (
      <GenderFilter
        mode={this.props.mode}
        mixedEnabled={this.props.config.mixedGenderEnabled}
        filter = {this.state.filter.gender}
        onChange={this.onUpdate}
        isMobile={true}
      />
    )
  }

  renderLocation = () => {
    return (
      <LocationFilter
        filter = {this.state.filter.location}
        venues = {this.props.config.venues}
        onChange={this.onUpdate}
        isNearbyEnabled={!this.isDisabled("nearby")}
        isSearchEnabled={!this.isDisabled("search")}
        isMobile={true} />
    )
  }

  onItemClicked = (func) => {
    this.setState({render: func})
  }

  onClick = () => {
    if(this.state.render) this.setState({render: null})
    else this.props.onUpdate(this.state.filter)
  }

  getSubtitle = (data, defaultText) => {
    if(isEmpty(data)) return defaultText
    const sortedData = data.sort((a,b) => a[0]-b[0])
    return sortedData.map(e => e[1]).toString()
  }

  getDaySubtitle = (data, defaultText) => {
    if(isEmpty(data)) return defaultText

    let sortedData = data.sort((a,b) => a[0]-b[0])
    if (this.props.mode === MODE_IMIN_FACILITY) {
      // Single selection only for facility
      // Return the first element of the selection
      return sortedData[0][1].toString();
    }

    return sortedData.map(e => e[1]).toString()
  }

  getTimeRangeSubtitle = (times, defaultText) => {
    if (
      times.from.hour === null && times.from.minute === null
      && times.to.hour === null && times.to.minute === null
    ) return defaultText

    const from_date = new Date();
    from_date.setHours(times.from.hour ?? null)
    from_date.setMinutes(times.from.minute ?? 0)
    const from_time = formatTime(from_date)

    const to_date = new Date();
    to_date.setHours(times.to.hour ?? null)
    to_date.setMinutes(times.to.minute ?? 0)
    const to_time = formatTime(to_date)

    let time_range = '';

    if (times.from.hour !== null) {
      time_range += from_time;
    }
    if (times.to.hour !== null) {
      time_range += times.from.hour !== null ? ' - ' : '';
      time_range += to_time;
    }


    return time_range;
  }

  getSportsSubtitle = (sportIds) => {
    if(isEmpty(sportIds)) return "Any sports"
    const sports = sportIds.map(id => Sports.get(id).getName())
    if(sports.length === 1) return sports[0]
    return `${sports.length} selected`
  }

  getLocationSubtitle = () => {
    const { type, venue, nearby, place } = this.state.filter.location
    let text

    if(type === TYPE_NEARBY) {
      if(nearby.lat === 0 && nearby.lng === 0) text = "Nearby - Location not found"
      else text = "Nearby - Location found"
    }
    else if(type === TYPE_PLACE) {
      if(place.lat === 0 && place.lng === 0) text = "Not found"
      else text = `${place.name}`
    }
    else {
      text = venue.name
    }

    return text
  }

  isDisabled(fieldName) {
    return isHidden(fieldName, this.props.hiddenFields)
  }

  onClearFilterClicked = () => {
    const clearedFilter = {days:[], times:[], sports:[],  gender: "any", time_ranges: {
        from: {
          hour: null,
          minute: null
        },
        to: {
          hour: null,
          minute: null
        }
      }}
    this.setState({filter: {...this.props.filter, ...clearedFilter, name: null}})
  }

  getTagsSubtitle = (type) => {
    const tags = Tags.get(type)
    const selectedTags = this.getTags(type)
    const count = tags.reduce((a,b) => a + (selectedTags.includes(b.slug) ? 1 : 0), 0)
    return count > 0 ? `${count} selected` : "Any"
  }

  getGenderSubtitle = (gender) => {
    const gender_option = getGendersOptions().find(item => item[0] === gender);
    return gender_option[1];
  }

  render() {
    const { filter } = this.state
    //const isInFilter = this.state.render !== null

    return (
      <div id="fp-mobile-container">
        <div className="top-bar">
          <CrossIcon className="close" onClick={this.props.onClose} />
          <span className="title">Filters</span>
          <span></span>
        </div>
        {
          this.state.render ?
          <div className="section">{this.state.render()}</div> :
          <div className="filters">

            <Disableable disabled={this.isDisabled("location")}>
              <DataView title="Location"
                subtitle={this.getLocationSubtitle()}
                onClick={()=>this.onItemClicked(this.renderLocation)} />
            </Disableable>

            <Disableable disabled={this.isDisabled("sports")}>
              <DataView title="Sports"
                subtitle={this.getSportsSubtitle(filter.sports)}
                onClick={()=>this.onItemClicked(this.renderSports)} />
            </Disableable>

            <Disableable disabled={this.isDisabled("days") || this.props.mode === MODE_CLUB}>
              <DataView title="Days"
                subtitle={this.getDaySubtitle(filter.days, this.props.mode === MODE_IMIN_FACILITY ? "Today" : "Any day")}
                onClick={()=>this.onItemClicked(this.renderDays)}/>
            </Disableable>

            <Disableable disabled={this.isDisabled("time")  || [MODE_CLUB, MODE_IMIN_FACILITY].includes(this.props.mode)}>
              <DataView title="Time"
                subtitle={this.getSubtitle(filter.times, "Any time")}
                onClick={()=>this.onItemClicked(this.renderTime)} />
            </Disableable>

            <Disableable disabled={this.props.mode !== MODE_IMIN_FACILITY}>
              <DataView title="Time"
                        subtitle={this.getTimeRangeSubtitle(filter.time_ranges, "Any time")}
                        onClick={()=>this.onItemClicked(this.renderTimeRange)} />
            </Disableable>

            <Disableable disabled={this.props.mode !== MODE_CLUB}>
              <DataView title="Type"
                subtitle={this.getTagsSubtitle(Tags.TEAM_TYPE)}
                onClick={()=>this.onItemClicked(()=>this.renderTags("Type", Tags.TEAM_TYPE))} />
            </Disableable>

            <Disableable disabled={this.props.mode !== MODE_CLUB}>
              <DataView title="Level"
                subtitle={this.getTagsSubtitle(Tags.TEAM_LEVEL)}
                onClick={()=>this.onItemClicked(()=>this.renderTags("Level", Tags.TEAM_LEVEL))} />
            </Disableable>

            <Disableable disabled={this.isDisabled("gender") || this.props.mode === MODE_IMIN_FACILITY}>
              <DataView title="Gender"
                subtitle={this.getGenderSubtitle(filter.gender)}
                onClick={()=>this.onItemClicked(this.renderGender)} />
            </Disableable>
          </div>
        }
        <div className="bottom-bar">
          { this.state.render ? null :
              <button className="clear" onClick={this.onClearFilterClicked}>Clear Filters</button> }
          <button onClick={this.onClick}>{this.state.render ? "Done" : "Search"}</button>
        </div>
      </div>
    )
  }
}
