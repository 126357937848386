import { getVar } from "@/utils"
import Sports from "./Sports"
import Tag from "./Tag"
import Tags from "./Tags"

export default class Organisation {
  constructor(org) {
    this.org = org
    this.orgTags = Tags.parse(org.tags)
    this.orgSports = Sports.getByIds(org.sport_ids)
  }

  getId() {
    return this.org.id
  }
  
  getCoordinates() {
    const loc = this.location
    return {lng: loc.longitude, lat: loc.latitude}
  }

  get object() {
    return this.org
  }

  get id() {
    return this.org.id
  }

  get groupId() {
    return this.org.members_group_id
  }

  get name() {
    return this.org.name
  }

  get about() {
    return this.org.about
  }

  get address() {
    return this.org.address
  }

  get tags() {
    return this.orgTags
  }

  get sports() {
    return this.orgSports
  }

  get avatarUrl() {
    return this.org.avatar_url
  }

  get headerUrl() {
    return this.org.header_image_url
  }

  get location() {
    return this.org.location
  }

  get shareUrl() {
    return this.org.branch_uri
  }

  get headerColour() {
    if(!this.org.header_colour) return getVar("--fp-blue")
    return this.org.header_colour
  }

  get eventsCount() {
    return this.org.upcoming_events
  }

  get adminIds() {
    return this.org.admin_ids
  }
}