import React from "react"
import { Popup } from "react-mapbox-gl"
import Sports from "@/models/Sports.js"
import { isEmpty } from "@/utils.js"
import { DEFAULT_CLUB_AVATAR } from "@/models/Constants.js"
import "./styles/OrgPopup.scss"
import "@/styles/common.scss"

export default function ClubPopup(props) {
  const club = props.club
  const loc = club.getCoordinates()
  const avatarUrl = club.avatarUrl
  const avatarSrc = isEmpty(avatarUrl)  || avatarUrl.endsWith("default_player.png") ?
                    DEFAULT_CLUB_AVATAR : 
                    avatarUrl

  let sports = club.sports
  if(sports.length > 6) sports = sports.slice(0,6)
  const sportItems = sports.map((s,index) => (
    <img
      className="sport" 
      style={{backgroundColor:s.getBackgroundColor()}}  
      src={s.getIconUrl()}
      alt="" 
      key={index}/>
  ))

  return (
    <Popup
      coordinates={[loc.lng, loc.lat]}
      anchor="top"
      onClick={props.onClick}>
        <div className="user-popup-container fp-unselectable fp-shadow">
          <img 
            className="avatar"
            src={avatarSrc}
            alt=""
            onError={(e) => {e.target.src=DEFAULT_CLUB_AVATAR}}
          />
          <div className="info">
            <span className="name">{club.name}</span>
            <div className="sports"> {sportItems} </div>
          </div>
        </div>
    </Popup>  
  )
}