import appContext from "@/AppContext"
import "@/styles/common.scss"
import { isEmpty } from "@/utils.js"
import DataView from "@/views/DataView.jsx"
import Option from "@/views/Option.jsx"
import React, { PureComponent } from "react"
import "./styles/Filter.scss"

export default class TimeFilter extends PureComponent {

  onChange = (e) => {
    const el = e.currentTarget
    const value = parseInt(el.value)
    const id = el.id
    let times = []//this.props.filter
    if(value === 0) {
      times = []
    }
    else {
      if(el.checked) times.push([value,id])
      else times = times.filter(v => v[0] !== value)
    }
    this.props.onChange({times:times})
  }

  getTime = (times) => {
    if(isEmpty(times)) return "Any time"
    const sortedTimes = times.sort((a,b) => a[0]-b[0])
    return sortedTimes.map(e => e[1]).toString()
  }


  renderMenu = (options) => {
    return (
      <div className="fp-dropdown filter-box">
        <div className="filter">
          <DataView 
           style={this.props.style} 
           title="Time"
           subtitle={this.getTime(this.props.filter)} 
           hideIcon />
        </div>
        <div className="fp-dropdown-box">
          <div className="filter-options fp-shadow">
            {options}
          </div>
        </div>
      </div>
    )
  }

  renderOptions = (options) => {
    return (
      <div>{ options }</div>
    )
  }

  render() {
    const filter = this.props.filter
    const times = [[0, "Any Time"],
      [1, "Early Morning"], [2, "Morning"], [3, "Afternoon"], 
      [4, "Evening"], [5, "Night"]
    ].map(item => (
      <Option
        type="radio"
        name="time"
        key={item[1]}
        id={item[1]}
        value={item[0]}
        text={item[1]}
        onChange={this.onChange}
        checked={
          (isEmpty(filter) && item[0] === 0) ||
          filter.filter(time => time[0]===item[0]).length > 0
        } />
      )
    )

    const elements = appContext.isMobile() ?
      this.renderOptions(times) :
      this.renderMenu(times)

    return elements
  }
}

TimeFilter.defaultProps = {
  filter: []
}