export default class Play {

  constructor(play) {
    this.play = play
  }

  getObject() {
    return this.play
  }

  getId() {
    return this.play.id
  }

  getPrice() {
    const price = parseFloat(this.play.price)
    const fees = parseFloat(this.play.buyer_fees)
    return price > 0 ? price + fees : 0
  }

  getFormattedPrice() {
    const price = this.getPrice()
    return price > 0 ? `£${price.toFixed(2)}` : 'FREE'
  }

  getMaxPlayers() {
    const maxPlayers = this.play.max_players
    return maxPlayers ? maxPlayers : 0;
  }

  getAttendingPlayers() {
    const players = this.play.players
    if(!players) return []
    return players.filter(p => p.state === "attending")
  }

  getSpotsFilled() {
    const openSpots = this.play.spots_filled
    return openSpots ? openSpots : 0;
  }

  showAsOpen() {
    const maxPlayers = this.getMaxPlayers()
    const spotsFilled = this.getSpotsFilled()
    return (maxPlayers === 0 || (spotsFilled < maxPlayers / 2))
  }

  getLocation() {
    const coords = this.play.venue.coordinates
    return {lat: coords.latitude, lng: coords.longitude}
  }
}