import "@/styles/common.scss"
import React from "react"
import { Popup } from "react-mapbox-gl"
import "./styles/ClusterPopup.scss"

export default function ClusterPopup(props) {

  const cluster = props.cluster

  return (
    <Popup
      coordinates={[cluster.lng, cluster.lat]}
      anchor="top"
      onClick={props.onClick}>

        <div className="fp-cluster-popup fp-shadow">
          <span 
            className="fp-highlight fp-unselectable" 
            onClick={props.onZoom}>
              Zoom
          </span>
          <span
            className="fp-highlight fp-unselectable" 
            onClick={()=>props.onShowAll(cluster)}>
              Show All
          </span>
        </div>

      </Popup>
  )
}