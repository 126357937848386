import ReactGA from "react-ga";
import { IS_PROD } from "./models/Constants";

class Analytics {
  init(name, code) {
    ReactGA.initialize(code, {
      gaOptions: { name },
    });
  }

  logPage(name) {
    if (IS_PROD) ReactGA.pageview(name);
  }

  logModal(name) {
    if (IS_PROD) ReactGA.modalview(name);
  }
}

const analytics = new Analytics();
export default analytics;
