const SECONDS_IN_MINUTE = 1000 * 60;
const SECONDS_IN_HOUR = SECONDS_IN_MINUTE * 60;
const SECONDS_IN_DAY = SECONDS_IN_HOUR * 24;

export function diffInMinutes(d1, d2) {
  return dateDiff(d1, d2, SECONDS_IN_MINUTE);
}

export function diffInHours(d1, d2) {
  return dateDiff(d1, d2, SECONDS_IN_HOUR);
}

export function diffInDays(d1, d2) {
  return dateDiff(d1, d2, SECONDS_IN_DAY);
}

function dateDiff(d1, d2, unit) {
  const utc1 = Date.UTC(d1.getFullYear(), d1.getMonth(), d1.getDate());
  const utc2 = Date.UTC(d2.getFullYear(), d2.getMonth(), d2.getDate());
  return Math.floor((utc2 - utc1) / unit);
}

export function formatDate(date) {
  let months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  let dateOfMonth = date.getDate();
  let month = date.getMonth();
  let postfix = getDateOrdinalIndicator(dateOfMonth);

  return dateOfMonth + postfix + " " + months[month];
}

export function getDay(date) {
  const days = ["Sun", "Mon", "Tue", "Wed", "Thurs", "Fri", "Sat"];
  return days[date.getDay()];
}

export function formatDateWithDay(date) {
  return getDay(date) + ", " + formatDate(date);
}

export function formatTime(time) {
  if (time === null || time === undefined) return null;
  let hour = time.getHours();
  let mins = time.getMinutes();
  let postfix = time.getHours() < 12 ? "am" : "pm";

  hour = hour % 12;
  hour = hour === 0 ? 12 : hour;

  return `${hour}${mins === 0 ? "" : ":"}${
    mins === 0 ? "" : formatTimeNumber(mins)
  }${postfix}`;
}

function formatTimeNumber(n) {
  return `${n < 10 ? "0" : ""}${n}`;
}

function getDateOrdinalIndicator(n) {
  if (n === 1 || n === 21 || n === 31) return "st";
  if (n === 2 || n === 22) return "nd";
  if (n === 3 || n === 23) return "rd";
  return "th";
}

/**
 * Returns the date of the next day. If today is friday and we are asking for next friday the friday of the next week is returned.
 * @param date Date
 * @param dayOfWeek 0:Su,1:Mo,2:Tu,3:We,4:Th,5:Fr,6:Sa
 */
export function getNextDayOfWeek(date, dayOfWeek) {
  date = date ?? new Date();
  const resultDate = new Date(date.getTime());
  resultDate.setDate(date.getDate() + (7 + dayOfWeek - date.getDay() - 1) % 7 +1);
  return resultDate;
}
