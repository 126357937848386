import React from "react"
import Toast from "@/views/Toast";
import {DEFAULT_AVATAR, MODE_IMIN_EVENT} from "@/models/Constants";
import Map from "@/components/main/map/Map.jsx";
import ReactMapboxGl from "react-mapbox-gl";
import appContext from "@/AppContext";
import analytics from "@/Analytics";
import {AttributionControl} from "mapbox-gl";
import {ReactComponent as BackArrow} from "@/images/arrow.svg";
import "./styles/IminEvent.scss";
import DataView from "@/views/DataView";
import {getVar} from "@/utils";
import Disableable from "@/views/Disableable";

const EventMapbox = ReactMapboxGl({
  accessToken: appContext.getMapboxToken(),
  interactive: false,
  logoPosition: "bottom-left",
  attributionControl: false,
});

const styles = {
  body: {
    backgroundColor: getVar("--fp-iminevent-mobile-info-background-color"),
  },
  title: { color: getVar("--fp-iminevent-mobile-info-title-color") },
  subtitle: { color: getVar("--fp-iminevent-mobile-info-subtitle-color") },
  icon: { fill: getVar("--fp-iminevent-mobile-info-icon-color") },
};

export default class IminEvent extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      message: null,
      id: props.id,
      event: props.event,
    };
    this.imgRef = React.createRef();
  }

  componentDidMount() {
    analytics.logPage("page_imin_event");
  }

  onMapLoaded = (map) => {
    map.addControl(new AttributionControl(), "top-left");
  };

  onBook = (slot) => {
    const event = this.state.event;
    const url = slot.slot?.url ?? event.imin_event.subEvent[0]?.url ?? event.imin_event?.organizer?.url ?? null;
    window.open(url);
  };

  onVenueClicked = (venue) => {
    const geo = venue.getCoordinates();
    const mapUrl =
      "https://www.google.com/maps/search/?api=1&query=" +
      encodeURI(`${geo.lat}, ${geo.lng}`);
    window.open(mapUrl);
  };

  renderBackButton = () => (
    <div className="fp-back-button" onClick={this.props.onClose}>
      <BackArrow/>
    </div>
  );

  renderAmenities = (amenity, index) => {
    return (
      <span key={index}>
        {amenity.name}
      </span>
    );
  }

  renderActivities = (activity, index) => {
    return (
      <span key={index}>
        {activity.prefLabel}
      </span>
    );
  }

  renderEventSlotItem = (slot, index) => {
    const event = this.state.event;

    return (
      <div className="slot-item" key={index}>
        <div className="slot-item__time">
          <span className="time">{slot.getFormattedDate()}</span>
          <span className="duration">{slot.getFormattedTime()}</span>
        </div>
        <div className="slot-item__price">
          <span className="price">
            {event.getFormattedCost()}
          </span>
          <Disableable disabled={slot.checkout === false}>
            <button
              className="fp-shadow fp-unselectable"
              onClick={() => this.onBook(slot)}
            >
              Book
            </button>
          </Disableable>
        </div>
      </div>
    );
  }

  renderMobileView() {

  }

  render() {
    const isMobile = appContext.isMobile();
    const event = this.state.event;

    const loc = event.getCoordinates();
    const address = event.getAddress();

    const amenities = event.getAmenities();
    const amenityList = amenities.map((item, index) => this.renderAmenities(item, index))

    const activities = event.getActivities();
    const activityList = activities.map((item, index) => this.renderActivities(item, index))

    const slots = event.getSlots();
    const slotList = slots.map((item, index) => this.renderEventSlotItem(item, index))

    const profileImage = (
      <img
        alt=""
        className="avatar"
        ref={this.imgRef}
        src={event.imageSrc}
        onError={(e) => {
          e.target.src = DEFAULT_AVATAR;
        }}
      />
    );

    return (
      <div id="fp-imin-event-page">
        <div className="imin-event-container">
          {this.state.message ? <Toast message={this.state.message}/> : null}

          {/* MAP */}
          <div className="imin-event-map">
            <Map
              instance={EventMapbox}
              data={[event]}
              zoom={15}
              showZoom={false}
              center={loc}
              mode={MODE_IMIN_EVENT}
              onMapLoaded={this.onMapLoaded}
              noClick
            />

            {appContext.isMobile() ? this.renderBackButton() : null}

            <div className="info">
              <div className="address">
                <span className="name">{event.location.name}</span>
                <span>{address}</span>
              </div>

              <div className="amenities">
                {amenityList}
              </div>
            </div>
          </div>
          {/* End of MAP */}

          <div className="header">
            {profileImage}
            <span>{event.name}</span>
          </div>

          <div className="imin-event-info">
            <div className="place-and-amenities">
              <DataView
                style={styles}
                title={event.location.name}
                subtitle={address}
                onClick={() => this.onVenueClicked(event)}
              />
              <div className="amenities">
                {amenityList}
              </div>
            </div>

            <div className="description">
              {event.description}
            </div>

            <div className="slots">
              <span className="date">
                Upcoming sessions
              </span>

              <div className="slots-container">
                {slotList}
              </div>
            </div>
          </div>

        </div>
      </div>
    );
  }

}
