import { DEFAULT_AVATAR } from "@/models/Constants.js"
import Sports from "@/models/Sports.js"
import { isEmpty } from "@/utils"
import React from "react"
import "@/styles/common.scss"
import "./styles/Results.scss"
import "./styles/UserListItem.scss"


export default class UserListItem extends React.PureComponent {

  renderSports = () => {
    let sportIds = this.props.user.getSportIds()
    if(sportIds.length > 6) sportIds = sportIds.slice(0,6)
    return sportIds.map((id,index) => {
      const sport = Sports.get(id)
      const style = { backgroundColor: sport.getBackgroundColor() }
      return (
        <div className="sportContainer" key={index}>
          <img 
            className="sportItem" 
            style={style} 
            src={sport.getIconUrl()} 
            alt="" 
            key={index}/>
        </div>
      )
    })
  }

  messageClicked = () => {
    this.props.onClick(this.props.user)
  }

  render() {
    const user = this.props.user
    const sports = this.renderSports()
    const description = user.getAbout()

    const avatarUrl = user.getAvatarUrl()
    const avatarSrc = isEmpty(avatarUrl)  || avatarUrl.endsWith("default_player.png") ?
                      DEFAULT_AVATAR : 
                      avatarUrl
    
    return (
      <div className="item">
        <div className="user-container">
          <div className="top">
          <img 
            className="avatar"
            src={avatarSrc}
            alt=""
            onError={(e) => {e.target.src=DEFAULT_AVATAR}}
          />
          <span className="title">{user.getName()}</span>
          <span className="subtitle">{user.getLocation()}</span>
          <div className="description fp-clamp-2">
            {description}
          </div>
        </div>
          <div className="bottom">
          <button className="fp-shadow" onClick={this.messageClicked}>Message</button>
          <div className="sport">
            {sports}
          </div>
        </div>
        </div>
      </div>
    )
  }
}