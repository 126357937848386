import React from "react"
import "@/styles/common.scss"
import "./styles/Results.scss"
import "./styles/FacilityListItem.scss"
import { DEFAULT_FACILITY_AVATAR } from "@/models/Constants";
import Disableable from "@/views/Disableable";

export default class FacilitiyListItem extends React.PureComponent {
  messageClicked = () => {
    this.props.onClick(this.props.facility)
  }

  render() {
    const facility = this.props.facility
    const price = facility.getFormattedCost()

    return (
      <div className="item">
        <div className="facility-container">
          <div className="top">
            <img
              className="avatar"
              src={facility.imageSrc}
              alt=""
              onError={(e) => {
                e.target.src = DEFAULT_FACILITY_AVATAR
              }}
            />
            <span className="title">{facility.name}</span>
            <span className="subtitle">{facility.address}</span>
            <div className="description fp-clamp-2">
              {facility.description}
            </div>
          </div>
          <div className="bottom">
            <button className="fp-shadow" onClick={this.messageClicked}>More Details</button>
            <Disableable disabled={price === null}>
              <div className="price">
                <span>{price}</span>
              </div>
            </Disableable>
          </div>
        </div>
      </div>
    )
  }
}
