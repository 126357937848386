import React from "react"
import { Popup } from "react-mapbox-gl"
import Sports from "@/models/Sports.js"
import { isEmpty } from "@/utils.js"
import { DEFAULT_AVATAR } from "@/models/Constants.js"
import "./styles/UserPopup.scss"
import "@/styles/common.scss"

export default function UserPopup(props) {
  const user = props.user
  const loc = user.getCoordinates()
  const avatarUrl = user.getAvatarUrl()
  const avatarSrc = isEmpty(avatarUrl)  || avatarUrl.endsWith("default_player.png") ?
                    DEFAULT_AVATAR : 
                    avatarUrl

  let sportIds = user.getSportIds()
  if(sportIds.length > 6) sportIds = sportIds.slice(0,6)
  const sports = sportIds.map(id => Sports.get(id))
  const sportItems = sports.map((s,index) => (
    <img
      className="sport" 
      style={{backgroundColor:s.getBackgroundColor()}}  
      src={s.getIconUrl()} 
      alt="" 
      key={index}/>
  ))

  return (
    <Popup
      coordinates={[loc.lng, loc.lat]}
      anchor="top"
      onClick={props.onClick}>
        <div className="user-popup-container fp-unselectable fp-shadow">
          <img 
            className="avatar"
            src={avatarSrc}
            alt=""
            onError={(e) => {e.target.src=DEFAULT_AVATAR}}
          />
          <div className="info">
            <span className="name">{user.getName()}</span>
            <div className="sports"> {sportItems} </div>
          </div>
        </div>
    </Popup>  
  )
}