import Facility from "@/models/Facility";
import IminEvent from "@/models/IminEvent";

export default class IminResponse {
  constructor(response) {
    this.response = response;
  }

  get item() {
    return this.total_items
      ? this.response['imin:item'].map((item) => {
        return item.type === 'FacilityUse'
          ? new Facility(item)
          : new IminEvent(item);
      })
      : [];
  }

  get total_items() {
    return this.response['imin:totalItems'];
  }

  get views() {
    return this.response.view
  }

  get current_page() {
    return this.views['imin:currentPage']
  }

  get items_per_page() {
    return this.views['imin:itemsPerPage']
  }

  get total_pages() {
    return this.views['imin:totalPages']
  }
}
