export default class Sport {

  constructor(sport) {
    this.sport = sport
  }
  
  getObject() {
    return this.sport
  }

  getId() {
    return this.sport.id
  }

  isFavourite() {
    return this.sport.favourite
  }

  getName() {
    return this.sport.name
  }

  getIconUrl() {
    return this.sport.icon_url
  }

  getBackgroundColor() {
    return this.sport.icon_background_colour
  }
}