import analytics from "@/Analytics";
import Api from "@/api/Api.js";
import appContext from "@/AppContext.js";
import Map from "@/components/main/map/Map.jsx";
import SignUp from "@/components/SignUp.jsx";
import { ReactComponent as BackArrow } from "@/images/arrow.svg";
import { ReactComponent as LastActiveIcon } from "@/images/last-active.svg";
import { ReactComponent as PersonIcon } from "@/images/person.svg";
import { DEFAULT_AVATAR, MODE_EVENT, MODE_PLAYER } from "@/models/Constants.js";
import Event from "@/models/Event.js";
import Sports from "@/models/Sports.js";
import "@/styles/common.scss";
import { formatDate, formatTime, getDay } from "@/time.js";
import { getLastActiveText, getVar, isEmpty } from "@/utils.js";
import DataView from "@/views/DataView.jsx";
import Modal from "@/views/Modal.jsx";
import Toast from "@/views/Toast.jsx";
import { AttributionControl } from "mapbox-gl";
import React from "react";
import Skeleton from "react-loading-skeleton";
import ReactMapboxGl from "react-mapbox-gl";
import "./styles/Event.scss";

let EventMapbox = ReactMapboxGl({
  accessToken: appContext.getMapboxToken(),
  interactive: false,
  logoPosition: "bottom-left",
  attributionControl: false,
});

export default class EventPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      message: null,
      id: props.id,
      event: props.event,
    };
    this.imgRef = React.createRef();
  }

  componentDidMount() {
    //this.imgRef.current.width = this.imgRef.current.height + 8 // 2*borderWidth
    Api.getEvent(this.state.id, (res, err) => {
      if (err) {
        this.setState({ message: "Error fetching event" });
        return;
      }
      res.event.sport = Sports.get(res.event.sport_id).sport;
      const event = new Event(res.event);
      if (event.getPlay() === null)
        this.setState({ message: "Game not found", event: null });
      else this.setState({ message: null, event });
    });

    analytics.logPage("page_event");
  }

  getGenderText = (gender) => {
    if (gender === null || gender === undefined) return "";
    if (gender === "mixed") return "Mixed Game";
    if (gender === "male") return "Male Only";
    if (gender === "female") return "Female Only";
    return "";
  };

  joinClicked = () => {
    const page = (
      <SignUp
        mode={MODE_EVENT}
        shareUrl={this.state.event.getShareUrl()}
        data={this.formatEventData()}
      />
    );
    appContext.showModal("signup", () => this.renderSignup(page));
  };

  organiserClicked = (organiser) => {
    const data = {
      type: "user",
      id: organiser.getId(),
      event_id: this.state.event.getId(),
      name: organiser.getName(),
      avatar_url: organiser.getAvatarUrl(),
    };

    const page = (
      <SignUp
        mode={MODE_PLAYER}
        data={data}
        shareUrl={organiser.getBranchUrl()}
      />
    );
    appContext.showModal("signup", () => this.renderSignup(page));
  };

  renderSignup = (page) => {
    return (
      <Modal
        style={{
          width: "90%",
          maxWidth: "680px",
        }}
        name="signup"
        showClose={!appContext.isMobile()}
      >
        {page}
      </Modal>
    );
  };

  formatEventData = () => {
    const event = this.state.event;
    const organiser = event.getOrganiser();
    const sport = event.getSport();

    const data = {
      type: "event",
      name: event.getName(),
      id: event.getId(),
      sport_id: event.getSport().getId(),
      sport_icon_url: sport.getIconUrl(),
    };

    if (organiser) {
      data.organiser_id = organiser.getId();
      data.avatar_url = organiser.getAvatarUrl();
    }

    return data;
  };

  onMapLoaded = (map) => {
    map.addControl(new AttributionControl(), "top-left");
  };

  onVenueClicked = (venue) => {
    const mapUrl =
      "https://www.google.com/maps/dir/?api=1&destination=" +
      encodeURI(venue.address);
    window.open(mapUrl);
  };

  renderBackButton = () => (
    <div className="fp-back-button" onClick={this.props.onClose}>
      <BackArrow />
    </div>
  );

  renderSkeleton = (msg) => {
    return (
      <div id="fp-event-page">
        <div className="event">
          <div className="event-container">
            {this.state.message ? <Toast message={this.state.message} /> : null}
            <div className="event-map">
              {appContext.isMobile() ? this.renderBackButton() : null}
              <div className="info">
                <div className="address">
                  <Skeleton count={1} width={180} />
                  <br />
                  <Skeleton count={1} width={180} />
                  <br />
                  <Skeleton count={1} width={180} />
                  <br />
                </div>
                <div className="time">
                  <Skeleton count={1} width={120} />
                  <br />
                  <Skeleton count={1} width={120} />
                </div>
              </div>
            </div>
            <div className="event-header">
              <div className="left">
                <Skeleton circle width={88} height={88} />
                <Skeleton count={1} width={80} />
              </div>

              <div className="right">
                <div className="last-active">
                  <Skeleton count={1} width={80} />
                </div>
                <Skeleton count={1} width={80} />
                <Skeleton count={1} width={80} />
              </div>
            </div>

            <div className="event-info">
              <div className="time-and-place">
                <div>
                  <Skeleton count={1} width={80} />
                </div>
                <div>
                  <Skeleton count={1} width={80} />
                </div>
              </div>
              <div className="description">
                <Skeleton count={1} width={180} />
                <br />
                <Skeleton count={1} width={80} />
              </div>
            </div>
          </div>

          <div className="event-bottom">
            <Skeleton count={1} width={80} />
            <Skeleton width={160} />
          </div>
        </div>
      </div>
    );
  };

  render() {
    const event = this.state.event;

    if (!event || !event.getPlay()) return this.renderSkeleton();

    const play = event.getPlay();
    const cost = event.getFormattedCost();
    const venue = play.getObject().venue;
    const loc = play.getLocation();
    const organiser = event.getOrganiser();
    const isJoinEnabled = this.props.config.eventJoinEnabled;

    const avatar = organiser ? organiser.getAvatarUrl() : DEFAULT_AVATAR;
    const avatarUrl =
      isEmpty(avatar) || avatar.endsWith("default_player.png")
        ? DEFAULT_AVATAR
        : avatar;

    const lastActive = getLastActiveText(event.getLastActive());

    const startDate =
      getDay(event.getStartTime()) + " " + formatDate(event.getStartTime());
    const time =
      formatTime(event.getStartTime()) + " - " + formatTime(event.getEndTime());

    const gender = this.getGenderText(event.getGender());
    const openSpots = play.showAsOpen()
      ? "Spots Open"
      : `${play.getAttendingPlayers().length} / ${play.getMaxPlayers()}`;

    const styles = {
      body: {
        backgroundColor: getVar("--fp-event-mobile-info-background-color"),
      },
      title: { color: getVar("--fp-event-mobile-info-title-color") },
      subtitle: { color: getVar("--fp-event-mobile-info-subtitle-color") },
      icon: { fill: getVar("--fp-event-mobile-info-icon-color") },
    };

    let profileImage = (
      <img
        alt=""
        className="avatar"
        ref={this.imgRef}
        src={avatarUrl}
        onError={(e) => {
          e.target.src = DEFAULT_AVATAR;
        }}
      />
    );

    return (
      <div id="fp-event-page">
        <div className="event-container">
          {this.state.message ? <Toast message={this.state.message} /> : null}
          <div className="event-map">
            <Map
              instance={EventMapbox}
              data={[event]}
              zoom={15}
              showZoom={false}
              center={loc}
              mode={MODE_EVENT}
              onMapLoaded={this.onMapLoaded}
              noClick
            />
            {appContext.isMobile() ? this.renderBackButton() : null}
            <div className="info">
              <div className="address">
                <span>{venue.name}</span>
                <span>{venue.address}</span>
              </div>
              <div className="time">
                <span>{startDate}</span>
                <span>{time}</span>
              </div>
            </div>
          </div>
          <div className="event-header">
            <div className="left">
              {profileImage}
              <span>{event.getName()}</span>
            </div>

            <div className="right">
              <div className="last-active">
                <LastActiveIcon />
                <span>{lastActive}</span>
              </div>
              <span>{gender}</span>
              <div className="open-spots">
                <PersonIcon />
                <span>{openSpots}</span>
              </div>
            </div>
          </div>

          <div className="event-info">
            <div className="time-and-place">
              <div>
                <DataView
                  style={styles}
                  title={startDate}
                  subtitle={time}
                  hideIcon
                />
              </div>
              <div>
                <DataView
                  style={styles}
                  title={venue.name}
                  subtitle={venue.address}
                  onClick={() => this.onVenueClicked(venue)}
                />
              </div>
            </div>
            <div className="description">
              {event.getDescription("No description set")}
            </div>
          </div>
        </div>

        <div className="event-bottom">
          <span>{cost}</span>
          <div className="event-buttons fp-unselectable">
            {organiser ? (
              <button
                className="fp-shadow org"
                onClick={() => this.organiserClicked(organiser)}
              >
                Contact
                <br />
                Organiser
              </button>
            ) : null}
            {isJoinEnabled ? (
              <button className="fp-shadow" onClick={this.joinClicked}>
                Ask to Join
              </button>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}
