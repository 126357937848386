import React from "react"
import "@/styles/common.scss"
import "./styles/Results.scss"
import "./styles/IminEventListItem.scss"
import { DEFAULT_EVENT_AVATAR } from "@/models/Constants";
import Disableable from "@/views/Disableable";

export default class IminEventListItem extends React.PureComponent {
  messageClicked = () => {
    this.props.onClick(this.props.event)
  }

  render() {
    const event = this.props.event
    const price = event.getFormattedCost()

    return (
      <div className="item">
        <div className="imin-event-container">
          <div className="top">
            <img
              className="avatar"
              src={event.imageSrc}
              alt=""
              onError={(e) => {
                e.target.src = DEFAULT_EVENT_AVATAR
              }}
            />
            <span className="title">{event.name}</span>
            <span className="subtitle">{event.address}</span>
            <div className="description fp-clamp-2">
              {event.description}
            </div>
          </div>
          <div className="bottom">
            <button className="fp-shadow" onClick={this.messageClicked}>More Details</button>
            <Disableable disabled={price === null}>
              <div className="price">
                <span>{price}</span>
              </div>
            </Disableable>
          </div>
        </div>
      </div>
    )
  }
}
