export default class Tag {

  constructor(type, tag) {
    this.type = type
    this.tag = tag
  }

  get id() {
    return this.tag.id
  }

  get name() {
    return this.tag.name
  }

  get slug() {
    return this.tag.slug
  }

  get category() {
    return this.type
  }
}