import { ReactComponent as ArrowIcon } from "@/images/arrow.svg";
import React from "react";
import "./styles/DataView.scss";

export default function DataView(props) {
  const { style } = props;

  return (
    <div
      className="fp-data-view"
      style={style.body}
      onClick={() => {
        props.onClick && props.onClick();
      }}
    >
      <div className="text">
        <span style={style.title} className="title">
          {props.title}
        </span>
        <span style={style.subtitle} className="subtitle">
          {props.subtitle}
        </span>
      </div>
      {props.hideIcon ? null : <ArrowIcon {...style.icon} />}
    </div>
  );
}

DataView.defaultProps = {
  style: {
    body: {},
    title: {},
    subtitle: {},
    icon: {},
  },
};
