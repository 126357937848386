import appContext from "@/AppContext.js";
import {
  MODE_CLUB,
  MODE_EVENT,
  MODE_IMIN_EVENT,
  MODE_IMIN_FACILITY,
  MODE_PLAYER,
  TYPE_VENUE,
} from "@/models/Constants.js";
import "@/styles/common.scss";
import Modal from "@/views/Modal.jsx";
import React from "react";
import analytics from "./Analytics.js";
import Org from "./components/club/Org.jsx";
import Event from "./components/event/Event.jsx";
import Main from "./components/main/Main.jsx";
import Player from "./components/player/Player.jsx";
import Facility from "./components/facility/Facility.jsx";
import Splash from "./components/splash/Splash.jsx";
import "./styles/App.scss";
import {isEmpty, getIntOr} from "./utils.js";
import IminEvent from "@/components/iminevent/IminEvent";

export default class App extends React.Component {
  // mql = window.matchMedia(`(max-width: 580px)`)
  // desktopQuery =  window.matchMedia(`(min-width: 1024px)`)

  constructor(props) {
    super(props);
    const {config, filter} = props;
    this.state = {
      filter: filter,
      config: config,
      resultId: config.id,
      resultItem: null,
      isLoading: true,
      form: null,
      modals: [],
    };

    this.appBarRef = React.createRef();
  }

  componentDidMount() {
    appContext.init(this);
    analytics.logPage("page_home");
  }

  updateState = (data, callback) => {
    this.setState(
      (prevState) => ({...prevState, ...data}),
      () => {
        appContext.init(this);
        if (callback !== null && typeof callback === "function") callback();
      }
    );
  };

  onLoadComplete = (config) => {
    if (config) {
      var update = {config: {...this.state.config, ...config}};
      const {id, mode} = this.parseBranch(config.branch);
      if (id > 0) {
        update.resultId = id;
        update.config.mode = mode;
      }
      if (!isEmpty(config.venues)) {
        const filter = {...this.state.filter};
        filter.location.type = TYPE_VENUE;
        filter.location.venue = config.venues[0];
        update = {...update, filter: filter};
      }
      this.updateState(update, () => this.finishInit());
    } else {
      this.finishInit();
    }
  };

  parseBranch(data) {
    const err = {id: 0, mode: null};
    if (!data || !data.uri) return err;
    let uri = data.uri;
    const parts = new URL(uri).pathname.split("/").filter((s) => s.length > 0);
    if (parts.length !== 2) return err;

    const id = getIntOr(parts[1], 0);
    if (id == 0) return err;

    if (parts[0] == "event") return {id, mode: MODE_EVENT};
    else if (parts[0] == "user") return {id, mode: MODE_PLAYER};
    else if (parts[0] == "organisation") return {id, mode: MODE_CLUB};
    return err;
  }

  finishInit = () => {
    this.updateState({isLoading: false}, () => {
      if (this.state.resultId > 0)
        appContext.showModal("result", this.renderResultItem);
    });
  };

  onClick = (resultItem) => {
    this.updateState(
      {resultItem: resultItem, resultId: resultItem.getId()},
      () => {
        appContext.showModal("result", this.renderResultItem);
      }
    );
  };

  onClose = () => {
    this.updateState({resultItem: null, resultId: 0}, () => {
      appContext.closeModal("result");
    });
  };

  onModeChange = (e) => {
    const el = e.currentTarget;
    this.setState({mode: el.id});
  };

  renderEvent = () => {
    return (
      <Event
        id={this.state.resultId}
        event={this.state.resultItem}
        config={this.state.config}
        onClose={this.onClose}
      />
    );
  };

  renderPlayer = () => {
    return (
      <Player
        id={this.state.resultId}
        user={this.state.resultItem}
        onClose={this.onClose}
      />
    );
  };

  renderFacility = () => {
    return (
      <Facility
        id={this.state.resultId}
        facility={this.state.resultItem}
        onClose={this.onClose}
      />
    );
  }

  renderIminEvent = () => {
    return (
      <IminEvent
        id={this.state.resultId}
        event={this.state.resultItem}
        onClose={this.onClose}
      />
    );
  }

  renderOrg = () => {
    return (
      <Org
        id={this.state.resultId}
        club={this.state.resultItem}
        onClose={this.onClose}
      />
    );
  };

  renderResultItem = () => {
    let resultPage;
    const mode = this.state.config.mode;

    if (mode === MODE_EVENT) resultPage = this.renderEvent();
    else if (mode === MODE_PLAYER) resultPage = this.renderPlayer();
    else if (mode === MODE_IMIN_FACILITY) resultPage = this.renderFacility();
    else if (mode === MODE_IMIN_EVENT) resultPage = this.renderIminEvent();
    else resultPage = this.renderOrg();

    const paddingTop = appContext.hideHeader()
      ? "0"
      : this.appBarRef.current.clientHeight + "px";

    if (appContext.isMobile())
      return (
        <div className="event-window" style={{paddingTop}}>
          {resultPage}
        </div>
      );

    return (
      <Modal
        name="result"
        showClose
        onClose={this.onClose}
        style={{
          width: "90%",
          maxWidth: mode == MODE_EVENT ? "910px" : "720px",
        }}
      >
        {resultPage}
      </Modal>
    );
  };

  render() {
    const {logo, logo2} = this.state.config;

    if (this.state.isLoading)
      return (
        <div id="fp-root">
          <Splash
            name={this.props.name}
            mode={this.state.config.mode}
            sportIds={this.state.config.sportIds}
            onLoad={this.onLoadComplete}
          />
        </div>
      );

    return (
      <div id="fp-root">
        {appContext.hideHeader() ? null : (
          <div ref={this.appBarRef} className="app-bar">
            {logo ? (
              <img
                alt="logo1"
                src={logo}
                onError={(e) => {
                  e.target.src = "";
                }}
              />
            ) : null}
            {logo2 ? (
              <img
                alt="logo2"
                src={logo2}
                onError={(e) => {
                  e.target.src = "";
                }}
              />
            ) : null}
          </div>
        )}
        <div className="fp-body">
          <Main
            config={this.state.config}
            mode={this.state.config.mode}
            filter={this.state.filter}
            onClick={this.onClick}
            onClose={this.onClose}
            form={this.state.form}
          />
        </div>
        {this.state.modals.map((m) => (
          <React.Fragment key={m.name}>{m.render()}</React.Fragment>
        ))}
      </div>
    );
  }
}
