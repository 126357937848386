import React from "react";
import "./styles/Loader.scss";
import "@/styles/common.scss";

export default function Loader(props) {
  return (
    <div id="fp-loader" className="fp-center">
      <div className="fp-spinner"></div>
    </div>
  );
}
