export const IS_STAGING_ENV = process.env.REACT_APP_ENV === "staging";

export const BRANCH_KEY = process.env.REACT_APP_BRANCH_KEY;

export const IS_PROD = process.env.REACT_APP_ENV === "production";
// TODO: Remove true
export const BASE_URL = IS_PROD || true
  ? "https://static.findaplayer.com/widgets/search/"
  : "https://fap-test.s3.eu-west-3.amazonaws.com/widgets/search/";

export const APP_URL = BASE_URL;
export const DEFAULT_LOGO_URL = APP_URL + "logo.png";

export const THEME_URL = APP_URL + "themes/";
export const PARTNER_URL = APP_URL + "partners/";

export const PER_PAGE = 120;

export const MODE_EVENT = "event";
export const MODE_PLAYER = "player";
export const MODE_CLUB = "club";
export const MODE_IMIN_FACILITY = "iminfacility";
export const MODE_IMIN_EVENT = "iminevent";

export const SPORT_KEY = "skill-sport";
export const IMAGES = process.env.PUBLIC_URL + "/";
export const DEFAULT_AVATAR = APP_URL + "default_avatar.png";
export const DEFAULT_CLUB_AVATAR = APP_URL + "default_club_avatar.png";
export const DEFAULT_FACILITY_AVATAR = APP_URL + "default_facility.png";
export const DEFAULT_EVENT_AVATAR = APP_URL + "images/events.png";

export const TYPE_NEARBY = "nearby";
export const TYPE_PLACE = "place";
export const TYPE_VENUE = "venue";
export const TYPE_MAP = "map";

export const MODE_MAP = "map";
export const MODE_LIST = "list";
export const MODE_MAP_AND_LIST = "map-list";

export const FORM_MOBILE = "mobile";
export const FORM_TABLET = "tablet";
export const FORM_DESKTOP = "desktop";
