import React from "react"

export default class HorizontalBar extends React.PureComponent {

  render() {
    const {min ,max, progress, width, height, color} = this.props
    const containerStyle = {
      width: width,
      height: height,
      border: `2px solid ${color}`,
      position: "relative"
    }
    const barStyle = {
      position: "absolute",
      top: 0,
      bottom: 0,
      left: 0,
      right: `${(1 - (progress-min)/(max-min)) * 100}%`,
      backgroundColor: color
    }
    return (
      <div style={containerStyle}>
        <div style={barStyle}></div>
      </div>
    )
  }
}

HorizontalBar.defaultProps = {
  width: "100%",
  height: "5px",
  color: "black",
  min: 0,
  max: 10,
  progress: 5
}