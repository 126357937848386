import {
  BRANCH_KEY,
  MODE_CLUB,
  MODE_EVENT,
  MODE_IMIN_EVENT,
  MODE_IMIN_FACILITY,
  MODE_PLAYER,
  PER_PAGE,
  TYPE_MAP,
  TYPE_NEARBY,
  TYPE_PLACE,
  TYPE_VENUE,
} from './models/Constants';
import { diffInDays, diffInHours, diffInMinutes } from "./time";
import Sports from "@/models/Sports";
import dayjs from "dayjs";

export function csvToArray(str) {
  if (isEmpty(str)) return [];
  return str.split(",").map((s) => s.trim());
}

export function isNumber(n) {
  return typeof n === "number";
}

export function getIntOr(n, def = 0) {
  if (!isNumber(def)) throw "Default value not specified";
  try {
    return parseInt(n, 10);
  } catch (err) {
    return def;
  }
}

export function idsToArray(str) {
  if (!str || str.length === 0) return [];
  return str
    .split(",")
    .map((val) => {
      if (val.includes("-")) return getRangeFromString(val);
      const parsed = parseInt(val, 10);
      if (isNaN(parsed)) throw `Invalid int value ${val}`;

      return parsed;
    })
    .flat(2);
}

export function getRangeFromString(rangeString) {
  if (!rangeString) return [];
  const parts = rangeString.split("-");
  const range = [];

  const start = parseInt(parts[0], 10);
  const end = parseInt(parts[1], 10);
  if (isNaN(start) || isNaN(end)) return [];

  for (let i = start; i <= end; i++) range.push(i);

  return range;
}

export function milesToMeters(miles) {
  return miles * 1609.34;
}

export function isHidden(fieldName, fields) {
  return fieldName && fields && fields.includes(fieldName);
}

export function sortSports2(sports) {
  sports.sort((s1, s2) => {
    const n1 = s1.getName();
    const n2 = s2.getName();
    if (s1.isFavourite()) return s2.isFavourite() ? compare(n1, n2) : -1;

    if (s2.isFavourite()) return s1.isFavourite() ? compare(n2, n1) : 1;

    return compare(n1, n2);
  });
}

export function sortSports(sports) {
  sports.sort((s1, s2) => {
    if (s1.favourite) return s2.favourite ? compare(s1.name, s2.name) : -1;

    if (s2.favourite) return s1.favourite ? compare(s2.name, s1.name) : 1;

    return compare(s1.name, s2.name);
  });
}

export function sortEvents(events) {
  if (events === null) return [];
  return events.sort((e1, e2) => {
    return compare(e1.plays[0].time_start, e2.plays[0].time_start);
  });
}

export function formatEventSearchTime(time) {
  if (time === null) return null;
  let hour = time.getHours();
  let min = time.getMinutes();
  return `${hour < 10 ? "0" : ""}${hour}:${min < 10 ? "0" : ""}${min}:00`;
}

export function getGendersOptions() {
  return [
    ["any", "Any"],
    ["male", "Male Only"],
    ["female", "Female Only"],
    ["mixed", "Mixed Only"]
  ];
}

export function isEmpty(str) {
  return str === undefined || str === null || str.length === 0;
}

function serializeTime(selectedDays, selectedTimes) {
  const times = getTimes(selectedTimes);
  let days = [...selectedDays];
  let schedule = {};

  if (days.length > 0) {
    if (times.length > 0)
      schedule = days.map((day) => ({
        day_of_week: day[0],
        time_of_day: times,
      }));
    else schedule = days.map((day) => ({ day_of_week: day[0] }));
  } else {
    if (times.length > 0) {
      days = [1, 2, 3, 4, 5, 6, 7];
      schedule = days.map((day) => ({
        day_of_week: day[0],
        time_of_day: times,
      }));
    }
  }
  return schedule;
}

function serializeSports(mode, selectedSports, sportIds) {
  if (isEmpty(selectedSports) && !isEmpty(sportIds)) selectedSports = sportIds;
  if (mode === MODE_PLAYER) return selectedSports.map((s) => ({ sport_id: s }));
  else if (mode === MODE_EVENT) return selectedSports.map((s) => ({ id: s }));
  else if (mode === MODE_IMIN_FACILITY) return selectedSports.map((s) => (Sports.get(s)?.sport.name));
  else if (mode === MODE_IMIN_EVENT) return selectedSports.map((s) => (Sports.get(s)?.sport.name));
  else return selectedSports;
}

export function getLocationFromFilter(filter) {
  if (!filter || !filter.type) return;

  const type = filter.type;
  let location = null;

  switch (type) {
    case TYPE_NEARBY:
      location = filter.nearby;
      break;
    case TYPE_PLACE:
      location = filter.place;
      break;
    case TYPE_VENUE:
      location = filter.venue;
      break;
    case TYPE_MAP:
      location = filter.map;
      break;
  }

  return location;
}

function makeClubFilter(config, filter) {
  const params = { per_page: PER_PAGE };

  if (!isEmpty(filter.name)) {
    params.name = filter.name;
    return params;
  }

  if (!isEmpty(config.orgIds)) params.scope_ids = config.orgIds;

  const location = getLocationFromFilter(filter.location);
  const distance = milesToMeters(filter.location.searchDistanceInMiles);

  if (location && location.lat !== 0 && location.lng !== 0) {
    params.coordinates = {
      longitude: location.lng,
      latitude: location.lat,
      proximity_threshold: distance,
    };
  }

  if (!isEmpty(filter.sports) || !isEmpty(config.sportIds))
    params.sports = serializeSports(MODE_CLUB, filter.sports, config.sportIds);

  if (!isEmpty(filter.team_level)) params.team_level = filter.team_level;

  if (!isEmpty(filter.team_type)) params.team_type = filter.team_type;

  log("Club Search", params);

  return params;
}

export function makeFilter(mode, config, filter) {
  if (mode === MODE_CLUB) return makeClubFilter(config, filter);

  const params = {};

  if (!isEmpty(config.orgIds))
    params.organisations = config.orgIds.map((id) => ({ id: id }));

  if (!isEmpty(filter.sports) || !isEmpty(config.sportIds))
    params.sports = serializeSports(mode, filter.sports, config.sportIds);

  if (!isEmpty(filter.name)) {
    params.name = filter.name;

    if (mode !== MODE_IMIN_FACILITY) {
      return params;
    }
  }

  if (filter.gender && filter.gender !== "any") params.gender = filter.gender;

  if (filter.days.length > 0 || filter.times.length > 0)
    params.schedule = serializeTime(filter.days, filter.times);

  if (mode === MODE_IMIN_FACILITY || mode === MODE_IMIN_EVENT) {
    params.time = filter.time_ranges;
  }

  const type = filter.location.type;
  const location = getLocationFromFilter(filter.location);
  let distance;

  if (type === TYPE_VENUE && mode === MODE_EVENT) distance = location.radius;
  else distance = milesToMeters(filter.location.searchDistanceInMiles);

  if (location && location.lat !== 0 && location.lng !== 0) {
    params.coordinates = {
      longitude: location.lng,
      latitude: location.lat,
      proximity_threshold: distance,
    };
  }

  params.per_page = PER_PAGE;
  log("Search params", params);

  return params;
}

export function makeIminFilter(filter, mode) {
  const { organisations, per_page, sports, schedule, time, ...rest } = filter;

  const params = {
    ...rest,
    'activityPrefLabel': sports,
    'limit': 50
  };

  const now = dayjs();

  if (mode === MODE_IMIN_FACILITY) {
    params.mode = 'upcoming-slots';

    // Date
    let same_day = false;

    // Day filter
    if (schedule?.length) {
      const day = schedule[0].day_of_week === 7 ? 0 : schedule[0].day_of_week;
      const additional_day = (7 + day - now.day() - 1) % 7 + 1;

      if (now.day() !== day) {
        const target_day = now.add(additional_day, 'day');

        let from_time = target_day
          .hour(time?.from?.hour ?? 0)
          .minute(time?.from?.minute ?? 0);
        let to_time = target_day
          .hour(time?.to?.hour ?? 23)
          .minute(time?.to?.minute ?? 59);

        params['startDate[gte]'] = from_time.format('YYYY-MM-DD[T]HH:mm:ss[Z]');
        params['startDate[lte]'] = to_time.format('YYYY-MM-DD[T]HH:mm:ss[Z]');
      } else {
        same_day = true;
      }
    }

    // If today
    if (schedule?.length === 0 || schedule === undefined || same_day) {
      let from_time = now
        .hour(time?.from?.hour ?? 0)
        .minute(time?.from?.minute ?? 0);
      let to_time = now
        .hour(time?.to?.hour ?? 23)
        .minute(time?.to?.minute ?? 59);

      if (now.isAfter(from_time)) {
        from_time = now.hour(now.hour()).minute(now.minute() + 1);
      }

      params['startDate[gte]'] = from_time.format('YYYY-MM-DD[T]HH:mm:ss[Z]');
      params['startDate[lte]'] = to_time.format('YYYY-MM-DD[T]HH:mm:ss[Z]');
    }
  } else {
    params.mode = 'upcoming-sessions';
    params.isVirtual = false;
    params.schedule = schedule;

    // Time
    let from_time = now
      .hour(time?.from?.hour ?? 0)
      .minute(time?.from?.minute ?? 0);
    let to_time = now
      .hour(time?.to?.hour ?? 23)
      .minute(time?.to?.minute ?? 59);

    // if (now.isAfter(from_time)) {
    //   from_time = now.hour(now.hour()).minute(now.minute() + 1);
    // }

    params['startTime[gte]'] = from_time.format('HH:mm');
    params['startTime[lte]'] = to_time.format('HH:mm');
  }

  return params;
}

function getTimeRange(timeOption) {
  switch (timeOption) {
    case 1:
      return ["00:00:00", "06:00:00"];
    case 2:
      return ["06:00:00", "12:00:00"];
    case 3:
      return ["12:00:00", "17:00:00"];
    case 4:
      return ["17:00:00", "20:00:00"];
    default:
      return ["20:00:00", "23:59:59"];
  }
}

function getTimes(times) {
  if (times.length === 0) return [];
  const timesArray = [];
  let range;
  times.forEach((time) => {
    range = getTimeRange(time[0]);
    timesArray.push({ at_or_after: range[0] });
    timesArray.push({ at_or_before: range[1] });
  });
  return timesArray;
}

function compare(s1, s2) {
  if (s1 < s2) return -1;
  if (s1 > s2) return 1;
  return 0;
}

export function geocode(name, callback) {
  const url = `${process.env.REACT_APP_IMIN_API_URL}/map/geocode?postal_code=${name}`;
  let req = new Request(url, { method: "GET" });
  fetch(req).then((response) => {
    if (response.status === 200) {
      response.json().then((json) => {
        if (json.results.length === 0) callback(null, "Not found");
        else callback(json.results[0].geometry.location, null);
      });
    } else {
      callback(null, "Error occurred");
    }
  });
}

export function currentLocation(sucess, error) {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(sucess, error);
  } else {
    error("Geolocation not supported");
  }
}

export function getVar(name) {
  return getComputedStyle(document.documentElement).getPropertyValue(name);
}

export function setVar(name, value) {
  document.documentElement.style.setProperty(name, value);
}

export function getLastActiveText(lastActive) {
  if (lastActive === null) return "Last active -";
  const now = new Date();

  let diff = diffInDays(lastActive, now);
  let text = null;

  if (diff >= 28) text = "over a month ago";
  else if (diff >= 21) text = "3 weeks ago";
  else if (diff >= 14) text = "2 weeks ago";
  else if (diff >= 7) text = "1 week ago";
  else if (diff > 1) text = diff + " days ago";
  else if (diff === 1) text = "a day ago";

  if (text !== null) return "Last active " + text;

  diff = diffInHours(lastActive, now);
  text = null;

  if (diff > 1) text = diff + " hours ago";
  else if (diff === 1) text = "a hour ago";

  if (text !== null) return "Last active " + text;

  diff = diffInMinutes(lastActive, now);
  text = null;

  if (diff >= 5) text = diff + " mins ago";
  else text = "just now";

  return "Last active " + text;
}

export function isValidLocation(location) {
  return location && location.lat !== 0 && location.lng !== 0;
}

export function log(...messages) {
  if (process.env.REACT_APP_ENV === "production") return;
  messages.forEach((msg) => console.log(msg));
}

export function loadScript(url) {
  return new Promise((res, rej) => {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = url;
    script.addEventListener("load", function () {
      res();
    });
    script.addEventListener("error", function (err) {
      rej(err);
    });
    document.body.appendChild(script);
  });
}

export function loadBranch(callback) {
  loadScript("https://cdn.branch.io/branch-latest.min.js")
    .then(() => {
      window.branch.init(BRANCH_KEY, callback);
    })
    .catch((err) => callback(err, null));
}

export function getModeText(mode, modes) {
  const m = modes.find((m) => m.id === mode);
  if (m) return m.name;
  else return m.id;
}

export function getModes(modes) {
  const defaultModes = [
    { id: MODE_EVENT, name: "Events" },
    { id: MODE_PLAYER, name: "Players" },
    { id: MODE_CLUB, name: "Clubs" },
    { id: MODE_IMIN_FACILITY, name: "Facilities" },
    { id: MODE_IMIN_EVENT, name: "Events" },
  ];
  const modeList = csvToArray(modes)
    .filter((m) => defaultModes.find((mode) => m.startsWith(mode.id)))
    .map((m) => {
      let mode;
      if (m.startsWith(MODE_EVENT)) mode = defaultModes[0];
      else if (m.startsWith(MODE_PLAYER)) mode = defaultModes[1];
      else if (m.startsWith(MODE_CLUB)) mode = defaultModes[2];
      else if (m.startsWith(MODE_IMIN_FACILITY)) mode = defaultModes[3];
      else if (m.startsWith(MODE_IMIN_EVENT)) mode = defaultModes[4];

      if (!mode) return null;

      if (m.includes(":")) {
        let parts = m.split(":");
        if (parts.length > 1 && parts[1].length > 0) {
          mode.name = parts[1];
        }
      }

      return mode;
    });

  if (isEmpty(modeList)) return defaultModes;
  return modeList;
}

/**
 * Serializes objects to query param
 * Use it for GET methods
 *
 * @param obj
 * @returns {string}
 */
export function serialize(obj) {
  let str = '?' + Object.keys(obj).reduce(function (a, k) {
    a.push(k + '=' + encodeURIComponent(obj[k]));
    return a;
  }, []).join('&');
  return str;
}

export function getConfigData() {
  return JSON.parse(`
  {
    "sportIds": [4],
    "eventJoinEnabled":false,
	  "hiddenFields": ["time"],
	  "mixedGenderEnabled": false,
	  "modes": [
      { "id": "player", "name": "Players" },
      { "id": "event", "name": "Events"}
    ],
    "noHeader": true,
    "venues": [
      {
        "name": "London",
        "lat": 51.50802694883861,
        "lng": -0.12761545818233266,
        "radius": 30000,
        "zoom": 11
      },
      {
        "name": "Glasgow",
        "lat": 55.874143,
        "lng": -4.290952,
        "radius": 30000,
        "zoom": 11
      }
    ]
  }
  `);
}

export function formatCurrency(value, currency = 'GBP', locale = 'en-GB') {
  const formatter = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currency,
  });

  return formatter.format(value);
}
