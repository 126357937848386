import Api from "@/api/Api.js";
import { DEFAULT_LOGO_URL, IS_PROD, PARTNER_URL } from "@/models/Constants";
import Sport from "@/models/Sport.js";
import Sports from "@/models/Sports.js";
import {
  getConfigData,
  getModes,
  isEmpty,
  loadBranch,
  log,
  sortSports,
} from "@/utils.js";
import React, { Component } from "react";
import appContext from "@/AppContext.js";
import TaskExecutor from "@/TaskExecutor";
import Tags from "@/models/Tags";
import "./styles/Splash.scss";

const MIN_SPLASH_TIME = 4000;
const TASK_SPORTS = 1;
const TASK_CONFIG = 2;
const TASK_TAGS = 3;
const TASK_BRANCH = 4;

export default class Splash extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: "Loading, please wait...",
      isError: false,
      sports: [],
      config: {},
    };

    this.exec = new TaskExecutor();
    this.branchData = {};

    this.exec.setTasksCompleteCallback(() => {
      if (!this.state.isError) this.loadComplete();
    });
  }

  componentDidMount() {
    this.time = new Date().getTime();
    this.runTasks();
  }

  loadComplete() {
    const diff = new Date().getTime() - this.time;
    const config = this.state.config;
    config.branch = this.branchData;
    if (diff < MIN_SPLASH_TIME)
      setTimeout(() => {
        this.props.onLoad(config);
      }, MIN_SPLASH_TIME - diff);
    else this.props.onLoad(config);
  }

  runTasks() {
    this.setState(
      { message: "Loading, please wait...", isError: false },
      () => {
        this.exec.clear();
        this.exec.addTask(TASK_SPORTS, this.getSports);
        this.exec.addTask(TASK_CONFIG, this.getConfig);
        //     this.exec.addTask(TASK_CONFIG, this.testConfig)
        this.exec.addTask(TASK_TAGS, this.getTags);
        this.exec.addTask(TASK_BRANCH, this.initBranch);
        this.exec.runAll();
      }
    );
  }

  initBranch = () => {
    loadBranch((err, data) => {
      log("Branch data", data);
      if (!err) {
        const uri = this.getUri(data);
        this.branchData = isEmpty(uri) ? {} : { uri };
      } else {
        log("Branch error", err);
      }
      this.exec.finish(TASK_BRANCH);
    });
  };

  getUri(data) {
    if (!data) return null;
    if (!isEmpty(data.uri)) return data.uri;
    if (!isEmpty(data.data)) {
      return JSON.parse(data.data).uri;
    }
    return null;
  }

  getSports = () => {
    Api.getAllSports((res, err) => this.onSportsFetched(res, err));
  };

  onSportsFetched = (res, err) => {
    if (err || res.status) {
      this.setState({ message: "Error occurred", isError: true }, () => {
        this.exec.finish(TASK_SPORTS);
      });
    } else {
      sortSports(res.sports);
      const sports = res.sports.map((e) => new Sport(e));
      Sports.init(sports);

      this.setSelectableSports(this.props.sportIds);
      this.exec.finish(TASK_SPORTS);
    }
  };

  setSelectableSports = (sportIds) => {
    if (!isEmpty(sportIds)) {
      const selectableSports = sportIds.map((id) => Sports.get(id));
      Sports.setSelectable(selectableSports);
    }
  };

  getTags = () => {
    Api.getTags((res, err) => {
      if (err) {
        this.setState({ message: "Error occurred", isError: true }, () => {
          this.exec.finish(TASK_TAGS);
        });
      } else {
        Tags.init(res);
        this.exec.finish(TASK_TAGS);
      }
    });
  };

  testConfig = () => {
    const config = getConfigData();
    this.setSelectableSports(config.sportIds);

    if (isEmpty(config.modes)) config.modes = getModes();
    if (isEmpty(config.mode)) config.mode = config.modes[0].id;

    if (!config.modes.find((m) => m.id === config.mode))
      config.mode = config.modes[0].id;

    this.setState({ config }, () => this.exec.finish(TASK_CONFIG));
  };

  getConfig = () => {
    const name = this.props.name;

    if (!IS_PROD || isEmpty(name)) {
      this.exec.finish(TASK_CONFIG);
      return;
    }

    const configUrl = `${PARTNER_URL}${name}/config.json`;

    Api.get(configUrl, (res, err) => {
      if (err) {
        this.setState({ message: "Error occurred", isError: true }, () => {
          this.exec.finish(TASK_CONFIG);
        });
      } else {
        this.setSelectableSports(res.sportIds);
        const config = res;

        if (isEmpty(config.modes)) config.modes = getModes();
        if (isEmpty(config.mode)) config.mode = config.modes[0].id;

        // For backwards compatibility
        if (!config.logo) config.logo = this.getDefaultLogoUrl(name);

        if (!config.modes.find((m) => m.id === config.mode))
          config.mode = config.modes[0].id;
        this.setState({ config }, () => this.exec.finish(TASK_CONFIG));
      }
    });
  };

  getDefaultLogoUrl = (name) => {
    if (IS_PROD && name) return `${PARTNER_URL}${name}/logo.png`;
    return DEFAULT_LOGO_URL;
  };

  render() {
    return (
      <div id="fp-splash">
        <div className="fp-layout">
          <span className="fp-powered">Powered By</span>
          <br />
          <div className="fp-logo" />
          <br />
          <span>{this.state.message}</span>
          <span style={{ fontSize: "12px", marginTop: "1em" }}>
            {appContext.appVersion}
          </span>
          {this.state.isError ? (
            <button className="fp-retry" onClick={() => this.runTasks()}>
              Retry
            </button>
          ) : null}
        </div>
      </div>
    );
  }
}

Splash.defaultProps = {
  sportIds: [],
};
