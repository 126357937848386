import appContext from "@/AppContext.js"
import { ReactComponent as FilterIcon } from "@/images/filter.svg"
import { ReactComponent as ListIcon } from "@/images/list.svg"
import { ReactComponent as MapIcon } from "@/images/map.svg"
import { ReactComponent as SearchIcon } from "@/images/search.svg"
import {MODE_CLUB, MODE_EVENT, MODE_IMIN_FACILITY, MODE_IMIN_EVENT, MODE_LIST, MODE_MAP} from "@/models/Constants.js"
import Tags from "@/models/Tags.js"
import "@/styles/common.scss"
import { getVar, isEmpty, isHidden } from "@/utils.js"
import Disableable from "@/views/Disableable.jsx"
import Modal from "@/views/Modal.jsx"
import React, { PureComponent } from "react"
import DayFilter from "./DayFilter.jsx"
import GenderFilter from "./GenderFilter.jsx"
import LocationFilter from "./LocationFilter.jsx"
import MobileFilter from "./MobileFilter.jsx"
import SearchModeFilter from "./SearchModeFilter.jsx"
import SportFilter from "./SportFilter.jsx"
import "./styles/Filter.scss"
import TagFilter from "./TagFilter.jsx"
import TimeFilter from "./TimeFilter.jsx"
import TimeRangeFilter from "@/components/main/filter/TimeRangeFilter";


export default class Filter extends PureComponent {

  constructor(props) {
    super(props)
    this.nameRef = React.createRef()
    this.goRef = React.createRef()
  }

  componentDidUpdate() {
    if(this.nameRef.current)
      this.nameRef.current.value = this.props.filter.name
  }

  renderMobile = () => {
    const newDisplayMode = this.props.mobileDisplayMode === MODE_LIST ? MODE_MAP : MODE_LIST
    const isMapMode = newDisplayMode === MODE_MAP;
    //const image = isMapMode ? "map.png" : "list.png"
    const text = isMapMode ? "Map" : "List"
    const icon = isMapMode ? <MapIcon /> : <ListIcon />
    return (
      <div id="mobile-filter-container">
        <div onClick={()=>appContext.showModal("mobile-filters", this.renderMobileFilters)}>
          <FilterIcon />
          <span>Update filters</span>
        </div>
        <span/>
        <div onClick={()=>this.props.onChangeDisplayMode(newDisplayMode)}>
          {icon}
          <span>{text}</span>
        </div>
        <Disableable disabled={[MODE_IMIN_FACILITY, MODE_IMIN_EVENT].includes(this.props.mode)}>
          <span/>
          <div onClick={()=>
            appContext.showModal("mobile-name-search", this.renderMobileSearchByName)} >
            <SearchIcon />
          </div>
        </Disableable>
      </div>
    )
  }

  onSearchNameChange = (e) => {
    const name = e.currentTarget.value
    if(this.timeout) clearTimeout(this.timeout)
    if(!isEmpty(name)) {
      this.timeout = setTimeout(() =>
        this.props.onUpdate({name: name}, true), 500);
    }
  }

  renderSearchByName = () => (
    <div className="search-bar fp-shadow">
      <SearchIcon
        onClick={()=>this.nameRef.current.focus()}/>
      <input
        ref={this.nameRef}
        type="text"
        placeholder="Search by name"
        onChange={this.onSearchNameChange}/>
    </div>
  )

  isDisabled(fieldName) {
    return isHidden(fieldName, this.props.hiddenFields)
  }

  renderMobileSearchByName =() => (
    <Modal
      style={{width:"80%"}}
      name="mobile-name-search"
      onClose={()=>appContext.closeModal("mobile-name-search")}>
      <div
        className="mobile-name-search">
        <input ref={this.nameRef}
          autoFocus
          placeholder={this.props.mode === MODE_EVENT ? "Event name" : "Player name"}></input>
        <button onClick={()=>{
          const name = this.nameRef.current.value
          appContext.closeModal("mobile-name-search", ()=> {
            this.props.onUpdate({name: name}, !isEmpty(name))
          })
        }}>
          Search
        </button>
      </div>
    </Modal>
  )

  onUpdate = () => {
    this.goRef.current.classList.remove("findaplayer-pulse")
    this.goRef.current.classList.add("findaplayer-pulse")
  }

  renderDesktop = () => {

    const style = {
      body: {},
      title: {
        color: getVar("--fp-filter-title-color")
      },
      subtitle: {
        color: getVar("--fp-filter-subtitle-color")
      }
    }

    return (
      <div className="container">
        <div className="filters fp-shadow">
        <Disableable disabled={this.isDisabled("search-mode")}>
          <SearchModeFilter mode={this.props.mode} modes={this.props.config.modes} />
          <span/>
        </Disableable>
        <Disableable disabled={this.isDisabled("location")}>
          <LocationFilter
            filter={this.props.filter.location}
            venues={this.props.config.venues}
            style={style}
            onChange={this.props.onUpdate}
            isNearbyEnabled={!this.isDisabled("nearby")}
            isSearchEnabled={!this.isDisabled("postcode")}
          />
          <span/>
        </Disableable>
        <Disableable disabled={this.isDisabled("sports")}>
          <SportFilter
            filter={this.props.filter.sports}
            style={style}
            onChange={this.props.onUpdate}
          />
          <span/>
        </Disableable>
        <Disableable disabled={this.props.mode === MODE_CLUB || this.isDisabled("day")}>
          <DayFilter
            type={this.props.mode === MODE_IMIN_FACILITY ? 'radio' : 'checkbox'}
            mode={this.props.mode}
            filter={this.props.filter.days}
            style={style}
            onChange={this.props.onUpdate}
          />
          <span/>
        </Disableable>
        <Disableable disabled={[MODE_CLUB, MODE_IMIN_FACILITY, MODE_IMIN_EVENT].includes(this.props.mode) || this.isDisabled("time")}>
          <TimeFilter
            filter={this.props.filter.times}
            style={style}
            onChange={this.props.onUpdate}
          />
          <span/>
        </Disableable>
        <Disableable disabled={![MODE_IMIN_FACILITY, MODE_IMIN_EVENT].includes(this.props.mode)}>
          <TimeRangeFilter
            filter={this.props.filter.time_ranges}
            style={style}
            onChange={this.props.onUpdate}
          />
        </Disableable>
        <Disableable disabled={this.props.mode !== MODE_CLUB}>
          <TagFilter
            filter={this.props.filter.team_type}
            type={Tags.TEAM_TYPE}
            title="Type"
            style={style}
            onChange={this.props.onUpdate}
          />
          <span/>
        </Disableable>
        <Disableable disabled={this.props.mode !== MODE_CLUB}>
          <TagFilter
            filter={this.props.filter.team_level}
            type={Tags.TEAM_LEVEL}
            title="Level"
            style={style}
            onChange={this.props.onUpdate}
          />
          <span/>
        </Disableable>
        <Disableable disabled={this.props.mode === MODE_IMIN_FACILITY}>
          <GenderFilter
            mode={this.props.mode}
            mixedEnabled={this.props.config.mixedGenderEnabled}
            filter={this.props.filter.gender}
            style={style}
            onChange={this.props.onUpdate}
          />
        </Disableable>
        <button
          ref = {this.goRef}
          className="search filter fp-shadow"
          onClick={()=>{this.props.onUpdate({name:null}, true)}}>Go!</button>
      </div>
    </div>
    )
  }

  renderMobileFilters = () => {
    return (
      <Modal
        name="mobile-filters"
        style={{padding: "0em 2em"}}
        onClose={()=>appContext.closeModal("mobile-filters")}>
            <MobileFilter
              filter={this.props.filter}
              mode={this.props.mode}
              config={this.props.config}
              hiddenFields={this.props.hiddenFields}
              onClose={()=>appContext.closeModal("mobile-filters")}
              onClear={
                (filter)=>this.props.onUpdate(filter, false)
              }
              onUpdate={
                (filter)=>
                  appContext.closeModal("mobile-filters",
                  ()=>this.props.onUpdate(filter, true))
              }
            />
          </Modal>
    )
  }

  render() {
    return(
      <div id="fp-filter-container">
        {
          appContext.isDesktop() && ![MODE_IMIN_FACILITY, MODE_IMIN_EVENT].includes(this.props.mode) ? this.renderSearchByName() : null
        }
        {
          appContext.isMobile() ? this.renderMobile() : this.renderDesktop()
        }
      </div>
    )
  }
}
