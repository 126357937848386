import {formatCurrency} from "@/utils";
import {formatTime} from "@/time";

export default class FacilitySlot {
  constructor(slot) {
    this.slot = slot;

    this.checkout = slot['imin:checkoutUrlTemplate']
      ? slot['imin:checkoutUrlTemplate'].replace('{checkoutBaseUrl}', process.env.REACT_APP_IMIN_CHECKOUT_URL)
      : false;
  }

  getPrice() {
    return this.slot['imin:aggregateOffer']?.publicAdult ?? this.slot['imin:aggregateOffer']?.publicJunior ?? false;
  }

  getDateTime() {
    return new Date(this.slot.startDate);
  }

  getFormattedTime() {
    return formatTime(this.getDateTime())
  }

  getDuration() {
    return this.slot.duration?.replace('PT', '') ?? ''
  }

  getFormattedCost() {
    const price = this.getPrice();
    return price
      ? formatCurrency(price.price, price.priceCurrency)
      : null;
  }
}
