import analytics from "@/Analytics"
import api from "@/api/Api.js"
import appContext from "@/AppContext.js"
import SignUp from "@/components/SignUp.jsx"
import { ReactComponent as BackArrow } from "@/images/arrow.svg"
import { ReactComponent as LastActiveIcon } from "@/images/last-active.svg"
import { ReactComponent as PinIcon } from "@/images/pin.svg"
import { DEFAULT_CLUB_AVATAR, MODE_CLUB, MODE_PLAYER } from "@/models/Constants.js"
import Organisation from "@/models/Organisation.js"
import User from "@/models/User"
import { getLastActiveText, getVar, isEmpty, log } from "@/utils.js"
import Button from "@/views/Button"
import Modal from "@/views/Modal.jsx"
import Toast from "@/views/Toast.jsx"
import { AttributionControl } from "mapbox-gl"
import React from "react"
import Skeleton from "react-loading-skeleton"
import "@/styles/common.scss"
import "./styles/Org.scss"

const TAGS_CUTOFF = 4;

export default class Club extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      message: null,
      id: props.id,
      club: props.club,
      isTagsExpanded: false,
      admin: null
    }
    this.imgRef = React.createRef()
    this.sports = props.club ? props.club.sports : []
  }

  componentDidMount() {
  /*  if(this.props.club) {
      this.getAdmin()
      return
    } */
    api.getOrg(this.state.id, (res,err) => {
      if(err) {
        this.setState({message: "Error fetching club"})
        return
      }
      this.setState({message:null, club: new Organisation(res)}, () => {
        analytics.logPage("page_club")
        this.getAdmin()
      })
    })
  }

  getAdmin = () => {
    const club = this.state.club
    if(isEmpty(club.adminIds)) return

    api.getUser(club.adminIds[0], (res,err) => {
      if(!err) {
        this.setState({admin: new User(res.user)}, () => {
          log("Admin", this.state.admin)
        })
      }
      else {
        log(err)
      }
    })
  }

  renderSignup = (page) => {
    return (
      <Modal
        style={{
          width:"90%",
          maxWidth: "680px"
        }} 
        name="signup"
        showClose={!appContext.isMobile()}>
          {page}
      </Modal>
    )
  }

  contactClicked = () => {
    const admin = this.state.admin
    const data = {
      type: "user", 
      id:admin.getId(),
      org_id: this.state.club.id, 
      name: admin.getName(),
      avatar_url: admin.getAvatarUrl()
    }
    const page = <SignUp mode={MODE_PLAYER} data={data} shareUrl={admin.getBranchUrl()}  />
    appContext.showModal("signup", ()=>this.renderSignup(page))
  }

  joinClicked = () => {
    const club = this.state.club
    const data = {
      type: "org",
      id: this.state.id,
      name: club.name,
      avatar_url: club.avatarUrl,
      header_url: club.headerUrl,
      group_id: club.groupId
    }
    const page = <SignUp mode={MODE_CLUB} data={data} shareUrl={this.state.club.shareUrl} />
    appContext.showModal("signup", ()=>this.renderSignup(page))
  }


  onMapLoaded = (map) => {
    map.addControl(new AttributionControl(), 'top-left');
  }

  onVenueClicked = (venue) => {
    const mapUrl = "https://www.google.com/maps/dir/?api=1&destination=" + encodeURI(venue.address)
    window.open(mapUrl)
  }

  renderMobileView = () => {
    const club = this.state.club
    const location = club.getCoordinates()
    const pinColor = getVar("--fp-club-mobile-icon-color")
    const lastActiveText = null
    const buttonStyle = {
      container: {
        padding: "0",
        justifyContent: "flex-start",
        display: "flex",
        flexDirection: "row",
        columnGap: "1em",
        alignItems: "center"
      },
      text: {

      }
    }

    return (
      <div className="mobile-view">
        <div className="mobile-info">
          <Button 
            text={club.address} 
            style={buttonStyle} 
            icon={<PinIcon width={24} height={24} wi stroke={pinColor} />} />
          <Button 
            text={`${club.eventsCount} upcoming events`} 
            style={buttonStyle} 
            icon={<LastActiveIcon width={18} stroke={pinColor} />} />
        </div>
      </div>
    )
  }

  renderBackButton = () => (
    <div className="fp-back-button" onClick={this.props.onClose}>
      <BackArrow />
    </div>
  )

  renderMobileSkeletonView = () => (
    <div className="mobile-view">
        <div className="mobile-stats">
          <div>
            <Skeleton width={80}/>
            <Skeleton width={160}/>
          </div>
          <div>
            <Skeleton width={80}/>
            <Skeleton width={160}/>
          </div>
        </div>
        <div className="mobile-info">
          <Skeleton width={80}/><br/>
          <Skeleton width={80}/>
       </div>
      </div>
  )

  renderSkeleton = () => {
    const isMobile = appContext.isMobile()
    return (  
      <div id="fp-org-page">
        <div className="org">
          <div className="org-container">
            {this.state.message ? <Toast message={this.state.message} /> : null}
            <div className="org-header">
              {appContext.isMobile()  ? this.renderBackButton() : null}
            </div>
            <div className="name-header" style={{backgroundColor:"#f5f5f5"}}>
              <img className="avatar" alt=""/>
              <div className="name-section">
                <Skeleton width={120} height={40}/>
                <Skeleton width={180}/>
              </div>
            </div> 
            { isMobile && this.renderMobileSkeletonView() }
            <div className="org-info">
              <div className="description">
                <Skeleton count={1} width={160}/><br/>
                <Skeleton count={1} width={80}/>
              </div>
            </div>
          </div>
          
          <div className="org-bottom">
            { 
              isMobile ? null :
              <div className="last-active"> 
                <Skeleton width={80}/>
              </div>
            }
            <Skeleton width={160} height={40}/>
          </div>
        </div>
      </div>
    )
  }

  renderTags = () => {
    const club = this.state.club
    let tags = club.tags
    let tagCount = tags.length

    let tagSpans = tags.map(t => (<span className="tag" key={t.slug}>{t.name}</span>))

    if(this.state.isTagsExpanded) {
      if(tagCount >= TAGS_CUTOFF) {
        tagSpans.push(<span className="more-tags" onClick={()=>this.setState({isTagsExpanded: false})}>Close</span>)
      }
    }
    else {
      let more = tagCount - TAGS_CUTOFF
      if(more > 0) {
        tagSpans = tagSpans.slice(0, TAGS_CUTOFF)
        tagSpans.push(<span className="more-tags" onClick={()=>this.setState({isTagsExpanded: true})}>+{more} more</span>)
      }
    }

    return tagSpans
  }

  render() {
    const club = this.state.club
    
    if(!club) return this.renderSkeleton()

    const loc   = club.getCoordinates()
    let avatarUrl = club.avatarUrl
    
    avatarUrl = isEmpty(avatarUrl)  || avatarUrl.endsWith("default_player.png") ?
                DEFAULT_CLUB_AVATAR : 
                avatarUrl

    const lastActive = getLastActiveText(null)
    const tags = this.renderTags()

    const styles = {
      body: {backgroundColor: getVar("--fp-event-mobile-info-background-color")},
      title: {color: getVar("--fp-event-mobile-info-title-color")},
      subtitle: {color: getVar("--fp-event-mobile-info-subtitle-color")},
    }

    let profileImage =
      <img 
        alt=""
        className="avatar"
        ref={this.imgRef}
        src={avatarUrl}
        onError={(e) => {e.target.src=DEFAULT_CLUB_AVATAR}} />

    const isMobile = appContext.isMobile()

    return (
      <div id="fp-org-page">

        <div className="org-container">
          {this.state.message ? <Toast message={this.state.message} /> : null}
          <div className="org-header">
            <img src={club.headerUrl}/>
            {appContext.isMobile()  ? this.renderBackButton() : null}
          </div>
          <div className="name-header" style={{backgroundColor: club.headerColour}}>
            {profileImage}
            <div className="name-section">
              <span className="name clamp-1">{club.name}</span>
              <span className="address clamp-1">{club.address}</span>
            </div>
          </div> 
          { isMobile && this.renderMobileView() }
          <div className="org-info">
            { tags.length > 0 ?
              <div className="tags">
                {tags}
              </div> :
              null
            }
            <div className="description">{club.about}</div>
          </div>
        </div>
        
        <div className="org-bottom">
          { 
            isMobile ? null :
            <div className="last-active">
              <LastActiveIcon />
              <span>{club.eventsCount} upcoming events</span>
            </div>
          }
          <div className="buttons">
            { this.state.admin ? 
              <button className="fp-shadow org" onClick={this.contactClicked}>
                Contact<br/>Manager
              </button> : null
            }
            <button 
              className="fp-shadow fp-unselectable" 
              onClick={this.joinClicked}>
                Join
            </button>
          </div>
        </div>

      </div>
    )
  }

}