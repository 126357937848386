import React, { PureComponent } from "react"
import EventListItem from "./EventListItem.jsx"
import UserListItem from "./UserListItem.jsx"
import {MODE_EVENT, MODE_IMIN_EVENT, MODE_IMIN_FACILITY, MODE_PLAYER} from "@/models/Constants"
import "./styles/Results.scss"
import "@/styles/common.scss"
import ClubListItem from "./ClubListItem.jsx"
import FacilitiyListItem from "./FacilitiyListItem.jsx";
import IminEventListItem from "@/components/main/results/IminEventListItem";
import Disableable from "@/views/Disableable";

export default class Results extends PureComponent {

  renderEvent = (item,index) => {
    return (
      <EventListItem
        key={index} 
        event={item} 
        onClick={this.props.onClick}/>
    )
  }

  renderPlayer = (item,index) => {
    return (
      <UserListItem
        key={index} 
        user={item} 
        onClick={this.props.onClick}
      />
    )
  }

  renderClub = (item, index) => {
    return (
      <ClubListItem
        key={index} 
        club={item} 
        onClick={this.props.onClick}
      />
    )
  }

  renderFacility = (item, index) => {
    return (
        <FacilitiyListItem
            key={index}
            facility={item}
            onClick={this.props.onClick}
        />
    )
  }

  renderIminEvent = (item, index) => {
    return (
      <IminEventListItem
        key={index}
        event={item}
        onClick={this.props.onClick}
      />
    )
  }

  render() {
    const mode = this.props.mode
    const {res, searching, modal} = this.props
    var renderItem

    if(mode === MODE_EVENT) renderItem = this.renderEvent
    else if(mode === MODE_PLAYER) renderItem = this.renderPlayer
    else if(mode === MODE_IMIN_FACILITY) renderItem = this.renderFacility
    else if(mode === MODE_IMIN_EVENT) renderItem = this.renderIminEvent
    else renderItem = this.renderClub

    const children = this.props.data.map((item,index) => renderItem(item,index))
    const has_load_more = !searching && !modal && res.current_page < res.total_pages;

    return (
      <div id="results-container">
        { children.length > 0 ? children : null }

        <Disableable disabled={!has_load_more}>
          <div className="list-footer">
            <button
              className="load-more"
              onClick={this.props.onLoadMore}
            >
              Load More
            </button>
          </div>
        </Disableable>
      </div>
    )
  }

}