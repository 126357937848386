import appContext from "@/AppContext";
import { MODE_CLUB } from "@/models/Constants";
import "@/styles/common.scss";
import DataView from "@/views/DataView.jsx";
import Option from "@/views/Option.jsx";
import { PureComponent } from "react";
import "./styles/Filter.scss";
import { getGendersOptions } from "@/utils";

export default class GenderFilter extends PureComponent {

  onChange = (e) => {
    const el = e.currentTarget;
    this.props.onChange({ gender: el.id });
  };

  renderOptions = (options) => {
    return <div> {options} </div>;
  };

  renderMenu = (options) => {
    const gender = this.props.filter;
    const gender_option = getGendersOptions().find(item => item[0] === gender)

    return (
      <div className="fp-dropdown filter-box">
        <div className="filter">
          <DataView
            style={this.props.style}
            title="Gender"
            subtitle={gender_option ? gender_option[1] : "Any"}
            hideIcon
          />
        </div>
        <div className="fp-dropdown-box">
          <div className="filter-options fp-shadow">{options}</div>
        </div>
      </div>
    );
  };

  render() {
    let buttons = [...getGendersOptions()];

    if (!this.props.mixedEnabled) buttons.pop();

    // @note Short circuited this as not sure if the API
    // can support multiple gender filters
    // - Jeff
    const isClubMode = this.props.mode === MODE_CLUB && false;

    buttons = buttons.map((item) => (
      <Option
        type={isClubMode ? "checkbox" : "radio"}
        id={item[0]}
        key={item[0]}
        name="gender"
        text={item[1]}
        onChange={this.onChange}
        checked={this.props.filter === item[0]}
      />
    ));

    const options = appContext.isMobile()
      ? this.renderOptions(buttons)
      : this.renderMenu(buttons);

    return options;
  }
}
