import appContext from "@/AppContext"
import "@/styles/common.scss"
import { isEmpty } from "@/utils.js"
import DataView from "@/views/DataView.jsx"
import Option from "@/views/Option.jsx"
import React from "react"
import "./styles/Filter.scss"
import {MODE_IMIN_FACILITY} from "@/models/Constants";

export default class DayFilter extends React.Component {

  onChange = (e) => {
    const el = e.currentTarget
    const day = el.id.substring(0,3)
    const value = parseInt(el.value)
    let days = this.props.filter

    if (this.props.type === 'checkbox') {
      if(el.checked) days.push([value,day])
      else days = days.filter(v => v[0] !== value)
    } else {
      days = [[value, day]];
    }

    this.props.onChange({days:days})
  }

  getDays = (days) => {
    if(isEmpty(days)) {
      return this.props.mode === MODE_IMIN_FACILITY ? "Today" : "All days"
    }
    const sortedDays = days.sort((a,b) => a[0]-b[0])
    return sortedDays.map(e => e[1]).toString()
  }

  renderMenu = (checkboxes) => {
    const type = this.props.type ?? 'checkbox';
    // If its a radio and has value, only select the first item
    const filter = type === 'checkbox'
      ? this.props.filter
      : this.props.filter.length ? [this.props.filter[0]] : []

    return (
      <div className="fp-dropdown filter-box">
        <div className="filter">
          <DataView style={this.props.style} title="Day" 
            subtitle={this.getDays(filter)} hideIcon />
        </div>
        <div className="fp-dropdown-box">
          <div className="filter-options fp-shadow">
            {checkboxes}
          </div>
        </div>
      </div>
    )
  }

  renderCheckboxes = (checkboxes) => {
    return (
      <div>{ checkboxes }</div>
    )
  }

  render() {
    const type = this.props.type ?? 'checkbox';
    // If its a radio and has value, only select the first item
    const filter = type === 'checkbox'
      ? this.props.filter
      : this.props.filter.length ? [this.props.filter[0]] : []

    const checkboxes =
      [[1,"Monday"], [2,"Tuesday"], [3,"Wednesday"], [4,"Thursday"],
        [5,"Friday"], [6,"Saturday"], [7,"Sunday"]]
        .map(
          item => (
            <Option
              type={type}
              key={item[1]}
              id={item[1]}
              value={item[0]} 
              text={item[1]}
              onChange={this.onChange}
              checked={filter.find(day => day[0] === item[0]) !== undefined} />
          )
        )

    const elements = appContext.isMobile() ? 
      this.renderCheckboxes(checkboxes) : 
      this.renderMenu(checkboxes)
      
    return elements
  }
}

/*
<div styleName="fp-dropdown">
        Day
        <div styleName="fp-dropdown-box shadow">
          {checkboxes}
        </div>
      </div>
*/
DayFilter.defaultProps = {
  filter: []
}