import { DEFAULT_CLUB_AVATAR } from "@/models/Constants.js"
import { isEmpty } from "@/utils"
import React from "react"
import "@/styles/common.scss"
import "./styles/Results.scss"
import "./styles/ClubListItem.scss"


export default class ClubListItem extends React.PureComponent {

  renderSports = () => {
    let sports = this.props.club.sports
    if(sports.length > 6) sports = sports.slice(0,6)

    return sports.map((sport,index) => {
      const style = { backgroundColor: sport.getBackgroundColor() }
      return (
        <div className="sportContainer" key={index}>
          <img 
            className="sportItem" 
            style={style} 
            src={sport.getIconUrl()} 
            alt="" 
            key={index}/>
        </div>
      )
    })
  }

  messageClicked = () => {
    this.props.onClick(this.props.club)
  }

  render() {
    const club = this.props.club
    const sports = this.renderSports()
    const description = club.about

    const avatarUrl = club.avatarUrl
    const avatarSrc = isEmpty(avatarUrl)  || avatarUrl.endsWith("default_player.png") ?
                      DEFAULT_CLUB_AVATAR :
                      avatarUrl
    
    return (
      <div className="item">
        <div className="club-container">
          <div className="top">
          <img 
            className="avatar"
            src={avatarSrc}
            alt=""
            onError={(e) => {e.target.src=DEFAULT_CLUB_AVATAR}}
          />
          <span className="title">{club.name}</span>
          <span className="subtitle">{club.address}</span>
          <div className="description fp-clamp-2">
            {description}
          </div>
        </div>
          <div className="bottom">
          <button className="fp-shadow" onClick={this.messageClicked}>More Details</button>
          <div className="sport">
            {sports}
          </div>
        </div>
        </div>
      </div>
    )
  }
}