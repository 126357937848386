import Tag from "./Tag"

export class Tags {

  constructor() {
    this.tags = []
    
    this.TEAM_TYPE = "team_type"
    this.TEAM_LEVEL = "team_level"
  }

  init(allTags) {
    this.tags = this.parse(allTags)
  }

  parse(allTags) {
    if(!allTags) return []

    const keys = Object.keys(allTags)
    const tagList = []

    keys.forEach(k => {
      const tags = allTags[k]
      if(tags) {
        tags.forEach(t => {
          tagList.push(new Tag(k,t))
        })
      }
    })

    return tagList
  }

  get(category) {
    return this.tags.filter(t => t.type === category)
  }

}

const _tags = new Tags()
export default _tags