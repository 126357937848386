import { rootElement } from "@/index.js";
import ResizeObserver from "resize-observer-polyfill";
import { FORM_DESKTOP, FORM_MOBILE, FORM_TABLET } from "./models/Constants";

class AppContext {
  appVersion = "v3.0";
  mapboxToken = process.env.REACT_APP_MAPBOX_TOKEN;

  init(app) {
    this.app = app;

    this.resizer = new ResizeObserver((entries) => {
      const el = entries[0];
      const width = el.contentRect.width;
      const form = this.app.state.form;
      let newForm;

      if (width < 720) newForm = FORM_MOBILE;
      else if (width > 1024) newForm = FORM_DESKTOP;
      else newForm = FORM_TABLET;

      if (newForm !== form) {
        this.resizer.disconnect();
        this.app.setState({ form: newForm }, () => {
          this.resizer.observe(rootElement);
        });
      }
    });
    this.resizer.observe(rootElement);
  }

  hideHeader() {
    return this.state().config.noHeader;
  }

  state() {
    return this.app.state;
  }

  isMobile() {
    return this.state().form === FORM_MOBILE;
  }

  isTablet() {
    return this.state().form === FORM_TABLET;
  }

  isDesktop() {
    return this.state().form === FORM_DESKTOP;
  }

  getMapboxToken() {
    return this.mapboxToken;
  }

  setMode(mode) {
    const config = this.state().config;
    this.app.setState({ config: { ...config, mode: mode } });
  }

  getMode() {
    return this.app.state.mode;
  }

  showModal(name, render) {
    if (!name || !render) return;
    const modals = [...this.app.state.modals];
    if (modals.filter((m) => m.name === name).length > 0) return;
    modals.push({ name: name, render: render });
    this.app.setState({ modals: modals });
  }

  closeModal(name, callback) {
    if (!name) return;
    const modals = this.app.state.modals.filter((m) => m.name !== name);
    this.app.setState({ modals: modals }, () => {
      callback && callback();
    });
  }

  isDev() {
    return process.env.REACT_APP_ENV === "development";
  }

  isStaging() {
    return process.env.REACT_APP_ENV === "staging";
  }
}

const appContext = new AppContext();
export default appContext;
