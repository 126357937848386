import { isEmpty } from "@/utils.js"
import { SPORT_KEY } from "@/models/Constants.js"

function getSessionItem(user, key) {
  if(!user.session_profile_datas) return null
  const item = user.session_profile_datas.filter((item) => item.key === key)
  return item && item.length > 0 ? item[0].value : null
}

function getSportIds(user) {
  const profileData = user.session_profile_datas
  if(!profileData) return []
  const sportItems = profileData.filter(item => item.key.startsWith(SPORT_KEY))
  const sportIds = sportItems.map(item => parseInt(item.key.substring(SPORT_KEY.length)))
  return sportIds
}

export default class User {

  constructor(user) {
    this.user = user
    this.user.sportIds = getSportIds(user)
  }

  getId() {
    return this.user.id
  }

  getBranchUrl() {
    return this.user.branch_uri
  }

  getSportIds() {
    return this.user.sportIds
  }

  setSports(sports) {
    this.user.sports = sports
  }

  getSports() {
    return this.user.sports
  }

  getName() {
    return getSessionItem(this.user, "name")
  }

  getCoordinates() {
    const { coordinates } = this.user
    if(!coordinates) return null
    return {lat: coordinates.latitude, lng: coordinates.longitude}
  }

  getLocation() {
    return getSessionItem(this.user, "location")
  }

  getAbout(defaultText) {
    const aboutMe = getSessionItem(this.user, "about_me")
    return !isEmpty(aboutMe) ? aboutMe : defaultText;
  }

  getGender() {
    return getSessionItem(this.user, "gender")
  }

  getAvatarUrl() {
    const item = getSessionItem(this.user, "avatar_url")
    return item ? item : "";
  }

  getLastActive() {
    return this.user.last_active ? new Date(this.user.last_active) : null
  }

  getFitness() {
    const value = parseInt(getSessionItem(this.user, "fitness_level"))
    return value ? value : 5;
  }

  getReliability() {
    const value = parseInt(getSessionItem(this.user, "reliability"))
    return value ? value : 5
  }
}