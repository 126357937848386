import api from "@/api/Api.js";
import { getModes, isEmpty, log, idsToArray, getIntOr } from "@/utils.js";
import analytics from "Analytics";
import "mapbox-gl/dist/mapbox-gl.css";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import {
  IS_PROD,
  MODE_CLUB,
  MODE_EVENT,
  MODE_PLAYER,
  PARTNER_URL,
  TYPE_MAP,
  TYPE_VENUE,
} from "./models/Constants";
import Filter from "./models/Filter";
import "./styles/animation.css";
import "./styles/index.scss";
import "./styles/theme.css";

export let rootElement =
  document.getElementById("findaplayer-search-widget") ||
  document.getElementById("findaplayer-event-widget") ||
  document.getElementById("findaplayer-player-widget");

if (rootElement) {
  const { name } = rootElement.dataset || "findaplayer";
  setFont();

  if (IS_PROD) {
    if (name) addLinkTag("stylesheet", `${PARTNER_URL}${name}/theme.css`);
    addMetaTag(
      "facebook-domain-verification",
      process.env.REACT_APP_FB_DOMAIN_ID
    );
    analytics.init(name, process.env.REACT_APP_GA_ID);
  }

  const params = getParams();

  ReactDOM.render(
    <React.StrictMode>
      <App
        config={params.config}
        filter={params.filter}
        logo={params.logo}
        logo2={params.logo2}
        name={name}
      />
    </React.StrictMode>,
    rootElement
  );
} else {
  console.error("Root element not found!");
}

function setFont() {
  let fontUrl =
    "https://fonts.googleapis.com/css2?family=Montserrat&display=swap";
  addLinkTag("preconnect", "https://fonts.gstatic.com");
  addLinkTag("stylesheet", fontUrl);
}

function addMetaTag(name, content) {
  var meta = document.createElement("meta");
  meta.name = name;
  meta.content = content;
  document.head.appendChild(meta);
}

function addLinkTag(rel, url) {
  if (!url) return;
  var head = document.head;
  var link = document.createElement("link");
  link.rel = rel;
  link.href = url;
  head.appendChild(link);
}

function getParams() {
  const { dataset } = rootElement;
  const urlParams = getUrlParams();

  const modes = getModes(dataset.modes);
  let mode = dataset.mode || modes[0].id;

  const urlLocation = parseCoordinates(urlParams["coordinates"]);
  const defaultLocation = parseCoordinates(dataset.defaultLocation);

  const type = urlParams["type"];
  const eventId = getIntOr(urlParams["event_id"], 0);
  const playerId = getIntOr(urlParams["player_id"], 0);
  const clubId = getIntOr(urlParams["club_id"], 0);

  if (eventId > 0) {
    mode = MODE_EVENT;
  } else if (playerId > 0) {
    mode = MODE_PLAYER;
  } else if (clubId > 0) {
    mode = MODE_CLUB;
  } else {
    mode = type || mode;
  }

  if (!modes.find((m) => m.id === mode)) mode = modes[0].id;

  let location = urlLocation ? urlLocation : defaultLocation;
  let filter = { ...Filter };

  log("Url Params", urlParams);

  const urlSportIds = idsToArray(urlParams["sport_ids"]);
  if (!isEmpty(urlSportIds))
    urlSportIds.forEach((id) => filter.sports.push(id));

  if (location) {
    filter = {
      ...filter,
      location: {
        ...Filter.location,
        type: TYPE_MAP,
        map: { lat: location.lat, lng: location.lng, zoom: 16 },
      },
    };
  }

  const params = {
    filter: filter,
    config: {
      mode,
      modes: modes,
      noHeader: dataset.noHeader,
      orgIds: parseIds(dataset.orgIds),
      sportIds: parseIds(dataset.sportIds),
      hiddenFields: getHiddenFields(dataset),
      defaultLocation: defaultLocation,
      isEventOnly: false,
      eventJoinEnabled: true,
      mixedGenderEnabled: true,
      id: eventId > 0 ? eventId : playerId > 0 ? playerId : clubId,
      venues: getVenues(dataset),
      logo: dataset.logo,
      logo2: dataset.logo2,
    },
  };

  log("Params", params);

  const venues = params.config.venues;

  if (!isEmpty(venues)) {
    params.filter.location.type = TYPE_VENUE;
    params.filter.location.venue = venues[0];
  }

  if (!params.config.defaultLocation)
    params.config.defaultLocation = { lat: 55.86, lng: -4.25 };

  if (dataset.apiBaseUrl) api.setBaseUrl(dataset.apiBaseUrl);

  params.filter.location.searchDistanceInMiles = getIntOr(
    dataset.distance,
    Filter.location.searchDistanceInMiles
  );

  return params;
}

function getHiddenFields(dataset) {
  const hiddenRx = /(.*)Hidden/;
  const hiddenFields = [];
  Object.keys(dataset).forEach((key) => {
    const result = hiddenRx.exec(key);
    if (result !== null) hiddenFields.push(result[1]);
  });
  return hiddenFields;
}

function getVenues(dataset) {
  const venueRx = /^venue-[0-9]*$/;
  const venues = [];
  Object.keys(dataset).forEach((key) => {
    if (venueRx.exec(key)) {
      const venue = parseVenue(dataset[key]);
      if (venue) venues.push(venue);
    }
  });

  return venues;
}

/* Venue format : name, lat, lng, radius, zoom */
function parseVenue(venue) {
  const parts = venue.split(",");
  if (!parts || parts.length < 3 || parts.length > 5) return null;
  const location = parseCoordinates(parts[1] + "," + parts[2]);
  const radius = parts.length > 3 ? getIntOr(parts[3], 50) : 50;
  const zoom = parts.length > 4 ? getIntOr(parts[4], 18) : 18;
  if (!location) return null;
  return {
    name: parts[0],
    lat: location.lat,
    lng: location.lng,
    radius: radius,
    zoom: zoom,
  };
}

function getUrlParams() {
  let urlParams = decodeURIComponent(window.location.search.substring(1));
  if (!urlParams || urlParams.length === 0) return {};

  let parts = urlParams.split("&");
  let part, query, key, value;
  let params = {};

  for (var i = 0; i < parts.length; i++) {
    part = parts[i];
    if (part.indexOf("=") < 0) continue;
    [key, value] = part.split("=");
    if (key == null || key.length === 0) continue;
    if (params[key]) params[key] = `${params[key]},${value}`;
    else params[key] = value;
  }

  return params;
}

function parseIds(ids) {
  if (!ids) return [];

  try {
    return idsToArray(ids);
  } catch (err) {
    console.error(err);
  }
  return null;
}

function parseCoordinates(coordinates) {
  if (!coordinates || coordinates.length === 0) return null;

  let parts = coordinates.split(",");

  if (parts.length !== 2) {
    console.error(
      "Invalid lat/lon format. Specify lat lon as a comma seperated list e.g. 55.86,-4.25"
    );
    return null;
  }

  let coords = null;

  try {
    let lat = parseFloat(parts[0]);
    let lng = parseFloat(parts[1]);

    if (isNaN(lat)) throw `Invalid lat value ${parts[0]}`;
    if (isNaN(lng)) throw `Invalid lon value ${parts[1]}`;

    coords = { lat, lng };
  } catch (err) {
    console.error(err);
  }

  return coords;
}
